import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IBasketItem } from 'src/app/exports/interfaces/IBasketItem';
import { ICheckoutItem } from 'src/app/exports/interfaces/ICheckoutItem';
import { IGroupedItem } from 'src/app/exports/interfaces/IGroupedItem';
import { IHttpResponse } from 'src/app/exports/interfaces/IHttpResponse';
import { BaoService } from 'src/app/services/bao.service';
import { MessageService } from 'src/app/services/message.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'tr[data-app-wrap-sales-page-item]',
  templateUrl: './wrap-sales-page-item.component.html',
  styleUrls: ['./wrap-sales-page-item.component.css']
})
export class WrapSalesPageItemComponent implements OnInit {

  @Input() item: ICheckoutItem;
  groupedItems: IGroupedItem[] = [];
  event_id: number = 0;

  constructor(
    private bao$: BaoService,
    private message$: MessageService,
    private router$: ActivatedRoute
  ) {
    this.router$.params.subscribe(params => {
      if (typeof params == "object" && params.hasOwnProperty('event_id')) {
        this.event_id = params.event_id;
      }
    });
   }

  ngOnInit(): void {
    this.groupedItems = this.groupBasketItemsByProduct(this.item.products);
  }


  groupBasketItemsByProduct(items: IBasketItem[]): IGroupedItem[] {
    const groupedItems: IGroupedItem[] = [];
    items.forEach(item => {
      if (this.event_id == 0 || this.event_id == item.product.id) {
        const existingGroup = groupedItems.find(group => group.product === item.product.id);
        if (existingGroup) {
          existingGroup.items.push(item);
        } else {
          groupedItems.push({
            product: item.product.id,
            items: [item]
          });
        }
      }
    });
    return groupedItems;
  }

  getProducts() {
    let name = '';
    this.item.products.map(item => {
      name += `${item.product.name} <br/>`;
    });

    return name;
  }

  getPrices() {
    let name = '';
    this.item.products.map(item => {
      name += `${item.pricing * item.quantity} р. <br/>`;
    });

    return name;
  }

  getQuantity() {
    let name = '';
    this.item.products.map(item => {
      name += `${item.quantity} <br/>`;
    });

    return name;
  }

  getTypes(item: IBasketItem) {
    return item.product.type.name;
  }

  getTimes() {
    let name = '';
    this.item.products.map(item => {
      name += `${formatDate(item.date.start_date, "HH:mm", "en-EN")} <br/>`;
    });

    return name;
  }

  getDates() {
    let name = '';
    this.item.products.map(item => {
      name += `${formatDate(item.date.start_date, "dd.MM.YYYY", "en-EN")} <br/>`;
    });

    return name;
  }

  repeatBilets(event: any) {
    event.target.disabled = true;
    this.bao$.repeatBilets(this.item.id).subscribe((response: IHttpResponse) => {
      if (response.results) {
        this.message$.handle('Билеты успешно отправлены на почту клиента', 'success');
      } else {
        this.message$.handle('Билеты не были отправлены, пожалуйста повторите попытку позже или обратитесь в администрацию');
      }
    });
  }

  getUserInfo() {
    let name = '';
    if (this.item.user) {
      if (this.item.user.surname || this.item.user.username) {
        name += `${this.item.user.surname} ${this.item.user.username}`;
      } else if (this.item.user.legal_first_name || this.item.user.legal_name) {
        name += `${this.item.user.legal_first_name} ${this.item.user.legal_name}`;
      }
    } else {
      if (this.item.f_surname) {
        name += this.item.f_surname;
      } 
      if (this.item.f_name) {
        name += ` ${this.item.f_name}`;
      }
    }

    return name;
  }

  getUserId() {
    if (this.item.user) {
      const userId = this.item.user.id
      return userId
    } else {
      return null
    }
  }

  getCategoryQuantities(items: IBasketItem[]): { categoryName: string, quantity: number, place: string }[] {
    const categoryQuantities: { categoryName: string, quantity: number, place: string }[] = [];
    const categoryQuantityMap = new Map<string, { quantity: number, place: string }>();
  
    items.forEach(item => {
      const categoryName = item.category ? item.category.name : 'Без категории';
      const place = item.place ? `${item.place.name} место, ${item.place.row}` : '';
      const current = categoryQuantityMap.get(categoryName) || { quantity: 0, place: place };
      categoryQuantityMap.set(categoryName, { quantity: current.quantity + item.quantity, place: current.place });
    });
  
    categoryQuantityMap.forEach((value, categoryName) => {
      categoryQuantities.push({ categoryName, quantity: value.quantity, place: value.place });
    });
  
    return categoryQuantities;
  }

  getTotalQuantity(items: IBasketItem[]): number {
    return items.reduce((total, item) => total + item.quantity, 0);
  }

  getTotalDiscount(items: IBasketItem[]): number {
    return items.reduce((total, item) => total + (item.discount_price * item.quantity), 0);
  }

  getTotalPrice(items: IBasketItem[]): number {
    return items.reduce((total, item) => total + (item.pricing * item.quantity), 0);
  }
}
