<div class="layout">
    <div data-app-header></div>
  
    <!-- ОСНОВНОЙ СЛАЙДЕР -->
    <div data-app-top-slider [isCategory]="true" [showGenre]="true"></div>
  
    <main class="layout__main">
  
        <div class="layout__section section">
            <div class="section__search search">
                <div class="container">
                    <div class="search__formular">
                        <form class="search__form" action="#">
                            <div class="row">
                                <div class="col col_9 col_tab-7 col_mob-12">
                                    <div class="form-item form-item_before">
                                        <div class="form-item__main">
                                            <div class="form-item__field">
                                                <div data-app-input 
                                                  placeholder="Поиск мероприятия..."
                                                  setClass="form-item__input"
                                                  sugg_url="/api/event/"
                                                  [get_data]="{
                                                    name: 'name',
                                                    value: 'name'
                                                  }"
                                                ></div>
                                            </div>
                                            <div class="form-item__media media">
                                                <svg class="media__ico">
                                                    <use xlink:href="/assets/img/sprite.svg#search"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div><!-- //form-item -->
                                </div>
                                <div class="col col_3 col_tab-5 col_mob-12">
                                    <div class="form-item  form-item_before">
                                        <div class="form-item__main">
                                            <div class="form-item__field">
                                                <div data-app-select 
                                                    setClass="form-item__input"
                                                    [values]="[
                                                        {
                                                            name: 'По дате',
                                                            value: 'start_date'
                                                        },
                                                        {
                                                            name: 'По названию',
                                                            value: 'name'
                                                        }
                                                    ]"
                                                    (data)="ordering($event)"
                                                ></div>
                                            </div>
                                            <div class="form-item__media media">
                                                <svg class="media__ico">
                                                    <use xlink:href="/assets/img/sprite.svg#date"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="section__main">
                <div class="container">
                    <div class="events">
                        <div class="events__list">
                            <div class="main__loading" *ngIf="isLoading">
                                <img src="/assets/img/content/loader/loader-spin.svg" alt="">
                              </div>
                            <div class="row">
                                <div class="col col_4 col_tab-6 col_mob-12" *ngFor="let item of items">
                                    <div data-app-event-block [item]="item"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div 
                *ngIf="response"
                data-app-pagination 
                dataType="events"
                [parameters]="params"
                [response]="response"
                [elements]="elements"
            ></div>
        </div>
  
    </main><!-- //middle -->
  
    <div data-app-footer></div>
  </div>  
