<div class="formular">
    <form [formGroup]="form">
        <div class="row">
            <div class="col col_6 col_tab-12">
                <div class="form__group group">
                    <div class="group__header">
                        <div class="wysiwyg">
                            <h3>Изменить пароль</h3>
                        </div>
                    </div>
                    <div class="group__main">
                        <!-- Старый пароль -->
                        <div class="form-item form-item_after">
                            <div class="form-item__header">
                                <label class="form-item__title"
                                       for="settings__old-password">Старый
                                    пароль</label>
                            </div>
                            <div class="form-item__main">
                                <div class="form-item__field">
                                    <div data-app-input
                                        [defaultValue]="defaultValue"
                                        [submitSubject]="formSubmit"
                                        setClass="form-item__input"
                                        [inputErrors]="errors"
                                        type="password"
                                        [c]="form.controls.oldpass"
                                        placeholder="Старый пароль"
                                        formControlName="oldpass"
                                    >
                                    </div>
                                </div>
                            </div>
                        </div><!-- //form-item -->
                        <!-- Новый пароль -->
                        <div class="form-item form-item_after">
                            <div class="form-item__header">
                                <label class="form-item__title"
                                       for="settings__new-password">Новый
                                    пароль</label>
                            </div>
                            <div class="form-item__main">
                                <div class="form-item__field">
                                    <div data-app-input
                                        [defaultValue]="defaultValue"
                                        [submitSubject]="formSubmit"
                                        setClass="form-item__input"
                                        [inputErrors]="errors"
                                        type="password"
                                        [c]="form.controls.password"
                                        placeholder="Придумайте пароль"
                                        formControlName="password"
                                    >
                                    </div>
                                </div>
                            </div>
                        </div><!-- //form-item -->
                        <!-- Подтвердите пароль -->
                        <div class="form-item form-item_after">
                            <div class="form-item__header">
                                <label class="form-item__title"
                                       for="settings__repeat-password">Подтвердите
                                    пароль</label>
                            </div>
                            <div class="form-item__main">
                                <div class="form-item__field">
                                    <div data-app-input
                                        [defaultValue]="defaultValue"
                                        [submitSubject]="formSubmit"
                                        setClass="form-item__input"
                                        [inputErrors]="errors"
                                        type="password"
                                        [c]="form.controls.confirmPassword"
                                        placeholder="Повторите новый пароль"
                                        formControlName="confirmPassword"
                                    >
                                    </div>
                                </div>
                            </div>
                        </div><!-- //form-item -->
                    </div>
                    <div class="group__footer">
                        <div class="form-item">
                            <div class="form-item__main">
                                <div class="form-item__field">
                                    <button type="button" class="btn" (click)="save()">
                                        Сохранить изменения
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item__main">
                                <div class="form-item__field">
                                    <button type="button" (click)="deleteAccount()"
                                            class="btn btn_tertiary w-auto">
                                        Удалить аккаунт
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>