import { Component, OnInit } from '@angular/core';
import { ICheckoutItem } from 'src/app/exports/interfaces/ICheckoutItem';
import { IHttpResponse } from 'src/app/exports/interfaces/IHttpResponse';
import { STATIC_SALES, STATIC_SALES_PARAMS, STATIC_SALES_RESPONSE } from 'src/app/exports/constans';
import { BaoService } from 'src/app/services/bao.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { IUserItem } from 'src/app/exports/interfaces/IUserItem';

@Component({
  selector: '[data-app-wrap-sales-page]',
  templateUrl: './wrap-sales-page.component.html',
  styleUrls: ['./wrap-sales-page.component.css']
})
export class WrapSalesPageComponent implements OnInit {

  items: ICheckoutItem[] = [];
  user: IUserItem;
  isLoading: boolean = true;
  event_id: number = 0;
  originalItems: ICheckoutItem[] = [];

  response = STATIC_SALES_RESPONSE;
  params = STATIC_SALES_PARAMS;
  elements = STATIC_SALES;

  
  constructor(
    private bao$: BaoService,
    private user$: UserService,
    private router$: ActivatedRoute
  ) {
    this.user$.user.subscribe(user => {
      if (user && typeof user == "object") {
        this.user = user;
      }
    });

    STATIC_SALES.subscribe(items => {
      if (Array.isArray(items)) {
        this.originalItems = items; // Store the original unfiltered events
        this.items = items;
      }
      
      this.isLoading = false;
    });
  }

  ngOnInit(): void {

  }

}