import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IBasketItem } from 'src/app/exports/interfaces/IBasketItem';
import { ICheckoutItem } from 'src/app/exports/interfaces/ICheckoutItem';
import { IGroupedItem } from 'src/app/exports/interfaces/IGroupedItem';
import { IHttpResponse } from 'src/app/exports/interfaces/IHttpResponse';
import { BaoService } from 'src/app/services/bao.service';
import { EventService } from 'src/app/services/event.service';
import { MessageService } from 'src/app/services/message.service';
import { ActivatedRoute } from '@angular/router';
import { IEventItem } from 'src/app/exports/interfaces/IEventItem';
import { IExportEvent } from 'src/app/exports/interfaces/IExportEvent';

@Component({
  selector: 'tr[data-app-wrap-service-page-item]',
  templateUrl: './wrap-service-page-item.component.html',
  styleUrls: ['./wrap-service-page-item.component.css']
})
export class WrapServicePageItemComponent implements OnInit {

  @Input() item: IExportEvent;
  event_id: number = 0;

  constructor(
    private bao$: BaoService,
    private event$: EventService,
    private message$: MessageService,
    private router$: ActivatedRoute
  ) {
    this.router$.params.subscribe(params => {
      if (typeof params == "object" && params.hasOwnProperty('event_id')) {
        this.event_id = params.event_id;
      }
    });
  }

  ngOnInit(): void {
  }

  exportEvent(item: any) {
    this.event$.exportEvent(item).subscribe((response: IHttpResponse) => {
      if (response.results) {
        console.log(response.results)
        this.message$.handle('Экспорт мероприятия прошел успешно', 'success');
      } else {
        this.message$.handle('При экспорте мероприятия появилась ошибка');
      }
    });
  }

  getMinimalPrice(price: any) {
    return 100
  }

}
