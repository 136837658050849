<div data-app-header></div>

<div class="layout">
    <main class="layout__main">
        <!-- partial:parts/_breadcrumbs.html -->
        <!-- // ХЛЕБНЫЕ КРОШКИ -->
        <div data-app-breadcrumbs></div>
        <!-- partial -->
        <!-- СЕКЦИЯ ОФОРМЛЕНИЯ ЗАКАЗА -->
        <div class="layout__section section">
            <div class="section__header">
                <div class="container">
                    <div class="wysiwyg">
                        <h1>Оформление заказа</h1>
                    </div>
                </div>
            </div>
            <div data-app-checkout-wrap></div>
        </div>
    </main><!-- //middle -->
</div>

<div data-app-footer></div>
