<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item">{{ item.name }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">{{ getRole() }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item">{{item.event ? item.event.name : '-' }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">
                {{ item.start_date ? (item.start_date | date: 'dd.MM.y') : '-' }}
            </span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">{{ item.finish_date ? (item.finish_date | date: 'dd.MM.y') : '-' }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">{{ item.count }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">{{ item.value }} р.</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <button type="button" (click)="delete()" class="btn btn_tertiary">Удалить</button>
        </div>
    </div>
</td>
