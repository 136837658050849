<div class="layout__section section">
    <div class="section__header">
        <div class="row">
            <div class="col col_10 col_desktop-9 col_mob-12">
                <div class="wysiwyg">
                    <h1>Спецпредложения</h1>
                </div>
            </div>
            <div class="col col_2 col_desktop-3 col_mob-12">
                <a routerLink="/account/specials/add" class="btn">Создать</a>
            </div>
        </div>
    </div>
    <div class="section__main" *ngIf="items.length > 0">
        <div class="table">
            <table class="table_custom table_special">
                <thead>
                    <tr>
                        <th class="no-wrap w-100">Название</th>
                        <th class="no-wrap">Жанр</th>
                        <th class="no-wrap">Мероприятия</th>
                        <th class="no-wrap">Действия</th>
                    </tr>
                </thead>
                <tbody>
                    <tr data-app-special-item
                        *ngFor="let item of items"
                        [item]="item"
                    ></tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
