<div class="layout__section section">
    <div class="section__header">
        <div class="wysiwyg">
            <h1>Схемы продаж</h1>
        </div>
    </div>
    <div class="section__main">
        <div class="account">
            <div class="account__schemes">
                <div class="formular">
                    <form>
                        <fieldset>
                            <div class="form__group group">
                                <div class="group__main">
                                    <div class="row">
                                        <div class="col col_6 col_tab-12">
                                            <div class="form-item">
                                                <div class="form-item__header">
                                                    <label class="form-item__title"
                                                           for="schemes__city">Выберете город</label>
                                                </div>
                                                <div class="form-item__main">
                                                    <div class="form-item__field">
                                                        <select id="schemes__city">
                                                            <option value="v1">Россия</option>
                                                            <option value="v2">Ураина</option>
                                                            <option value="v3">Англия</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div><!-- //form-item -->
                                        </div>
                                        <div class="col col_6 col_tab-12">
                                            <div class="form-item">
                                                <div class="form-item__header">
                                                    <label class="form-item__title"
                                                           for="schemes__themes">Выберете схему</label>
                                                </div>
                                                <div class="form-item__main">
                                                    <div class="form-item__field">
                                                        <select id="schemes__themes">
                                                            <option value="v1">Список</option>
                                                            <option value="v2">Список</option>
                                                            <option value="v3">Список</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div><!-- //form-item -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div class="schemes">
                    <div class="schemes__list">
                        <div class="row">
                            <div class="col col_3 col_tab-6 col_mob-12">
                                <div class="schemes__item">
                                    <div class="schemes__header">
                                        <div class="schemes__media">
                                            <img class="media__img" data-src="img/content/event/event__schemes__img01.jpg" src="img/content/event/event__schemes__img01.jpg" alt="image description">
                                        </div>
                                        <div class="schemes__action">
                                            <span class="btn modal-init" data-modalname="modal__layout_scheme">Смотреть</span>
                                        </div>
                                    </div>
                                    <div class="schemes__main">
                                        <div class="schemes__title title">
                                            <span class="title__text">Зал 1</span>
                                        </div>
                                        <div class="schemes__location location">
                                            <div class="location__media media">
                                                <svg class="media__ico">
                                                    <use xlink:href="img/sprite.svg#location"></use>
                                                </svg>
                                            </div>
                                            <div class="location__title title">
                                                <span class="title__text">Клуб «Punk Fiction»</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col_3 col_tab-6 col_mob-12">
                                <div class="schemes__item">
                                    <div class="schemes__header">
                                        <div class="schemes__media">
                                            <img class="media__img" data-src="img/content/event/event__schemes__img01.jpg" src="img/content/event/event__schemes__img01.jpg" alt="image description">
                                        </div>
                                        <div class="schemes__action">
                                            <span class="btn modal-init" data-modalname="modal__layout_scheme">Смотреть</span>
                                        </div>
                                    </div>
                                    <div class="schemes__main">
                                        <div class="schemes__title title">
                                            <span class="title__text">Зал 1</span>
                                        </div>
                                        <div class="schemes__location location">
                                            <div class="location__media media">
                                                <svg class="media__ico">
                                                    <use xlink:href="img/sprite.svg#location"></use>
                                                </svg>
                                            </div>
                                            <div class="location__title title">
                                                <span class="title__text">Клуб «Punk Fiction»</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col_3 col_tab-6 col_mob-12">
                                <div class="schemes__item">
                                    <div class="schemes__header">
                                        <div class="schemes__media">
                                            <img class="media__img" data-src="img/content/event/event__schemes__img01.jpg" src="img/content/event/event__schemes__img01.jpg" alt="image description">
                                        </div>
                                        <div class="schemes__action">
                                            <span class="btn modal-init" data-modalname="modal__layout_scheme">Смотреть</span>
                                        </div>
                                    </div>
                                    <div class="schemes__main">
                                        <div class="schemes__title title">
                                            <span class="title__text">Зал 1</span>
                                        </div>
                                        <div class="schemes__location location">
                                            <div class="location__media media">
                                                <svg class="media__ico">
                                                    <use xlink:href="img/sprite.svg#location"></use>
                                                </svg>
                                            </div>
                                            <div class="location__title title">
                                                <span class="title__text">Клуб «Punk Fiction»</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col_3 col_tab-6 col_mob-12">
                                <div class="schemes__item">
                                    <div class="schemes__header">
                                        <div class="schemes__media">
                                            <img class="media__img" data-src="img/content/event/event__schemes__img01.jpg" src="img/content/event/event__schemes__img01.jpg" alt="image description">
                                        </div>
                                        <div class="schemes__action">
                                            <span class="btn modal-init" data-modalname="modal__layout_scheme">Смотреть</span>
                                        </div>
                                    </div>
                                    <div class="schemes__main">
                                        <div class="schemes__title title">
                                            <span class="title__text">Зал 1</span>
                                        </div>
                                        <div class="schemes__location location">
                                            <div class="location__media media">
                                                <svg class="media__ico">
                                                    <use xlink:href="img/sprite.svg#location"></use>
                                                </svg>
                                            </div>
                                            <div class="location__title title">
                                                <span class="title__text">Клуб «Punk Fiction»</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col col_3 col_tab-6 col_mob-12">
                                <div class="schemes__item">
                                    <div class="schemes__header">
                                        <div class="schemes__media">
                                            <img class="media__img" data-src="img/content/event/event__schemes__img01.jpg" src="img/content/event/event__schemes__img01.jpg" alt="image description">
                                        </div>
                                        <div class="schemes__action">
                                            <span class="btn modal-init" data-modalname="modal__layout_scheme">Смотреть</span>
                                        </div>
                                    </div>
                                    <div class="schemes__main">
                                        <div class="schemes__title title">
                                            <span class="title__text">Зал 1</span>
                                        </div>
                                        <div class="schemes__location location">
                                            <div class="location__media media">
                                                <svg class="media__ico">
                                                    <use xlink:href="img/sprite.svg#location"></use>
                                                </svg>
                                            </div>
                                            <div class="location__title title">
                                                <span class="title__text">Клуб «Punk Fiction»</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col_3 col_tab-6 col_mob-12">
                                <div class="schemes__item">
                                    <div class="schemes__header">
                                        <div class="schemes__media">
                                            <img class="media__img" data-src="img/content/event/event__schemes__img01.jpg" src="img/content/event/event__schemes__img01.jpg" alt="image description">
                                        </div>
                                        <div class="schemes__action">
                                            <span class="btn modal-init" data-modalname="modal__layout_scheme">Смотреть</span>
                                        </div>
                                    </div>
                                    <div class="schemes__main">
                                        <div class="schemes__title title">
                                            <span class="title__text">Зал 1</span>
                                        </div>
                                        <div class="schemes__location location">
                                            <div class="location__media media">
                                                <svg class="media__ico">
                                                    <use xlink:href="img/sprite.svg#location"></use>
                                                </svg>
                                            </div>
                                            <div class="location__title title">
                                                <span class="title__text">Клуб «Punk Fiction»</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col_3 col_tab-6 col_mob-12">
                                <div class="schemes__item">
                                    <div class="schemes__header">
                                        <div class="schemes__media">
                                            <img class="media__img" data-src="img/content/event/event__schemes__img01.jpg" src="img/content/event/event__schemes__img01.jpg" alt="image description">
                                        </div>
                                        <div class="schemes__action">
                                            <span class="btn modal-init" data-modalname="modal__layout_scheme">Смотреть</span>
                                        </div>
                                    </div>
                                    <div class="schemes__main">
                                        <div class="schemes__title title">
                                            <span class="title__text">Зал 1</span>
                                        </div>
                                        <div class="schemes__location location">
                                            <div class="location__media media">
                                                <svg class="media__ico">
                                                    <use xlink:href="img/sprite.svg#location"></use>
                                                </svg>
                                            </div>
                                            <div class="location__title title">
                                                <span class="title__text">Клуб «Punk Fiction»</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col_3 col_tab-6 col_mob-12">
                                <div class="schemes__item">
                                    <div class="schemes__header">
                                        <div class="schemes__media">
                                            <img class="media__img" data-src="img/content/event/event__schemes__img01.jpg" src="img/content/event/event__schemes__img01.jpg" alt="image description">
                                        </div>
                                        <div class="schemes__action">
                                            <span class="btn modal-init" data-modalname="modal__layout_scheme">Смотреть</span>
                                        </div>
                                    </div>
                                    <div class="schemes__main">
                                        <div class="schemes__title title">
                                            <span class="title__text">Зал 1</span>
                                        </div>
                                        <div class="schemes__location location">
                                            <div class="location__media media">
                                                <svg class="media__ico">
                                                    <use xlink:href="img/sprite.svg#location"></use>
                                                </svg>
                                            </div>
                                            <div class="location__title title">
                                                <span class="title__text">Клуб «Punk Fiction»</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>