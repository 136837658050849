import { Component, Input, OnInit } from '@angular/core';
import { ICheckoutReturn } from 'src/app/exports/interfaces/ICheckoutReturn';

@Component({
  selector: '[data-app-params-return-page-item]',
  templateUrl: './params-return-page-item.component.html',
  styleUrls: ['./params-return-page-item.component.css']
})
export class ParamsReturnPageItemComponent implements OnInit {

  @Input() item: ICheckoutReturn;
  basketIds: string;

  constructor() { }

  ngOnInit(): void {
    const basketIdsArray = this.item.baskets.map(basket => basket.id);
    this.basketIds = basketIdsArray.join(', ');
  }



  getNames() {
    return this.item.baskets[0].product.name;
  }

}
