import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { RandomStringFunction } from 'src/app/exports/functions/RandomStringFunction';
import { IDateItem } from 'src/app/exports/interfaces/IDateItem';
import { IEventAreaCategory } from 'src/app/exports/interfaces/IEventAreaCategory';
import { IEventAreaSchems } from 'src/app/exports/interfaces/IEventAreaSchems';
import { IEventItem } from 'src/app/exports/interfaces/IEventItem';
import { IEventAreaPlaces } from 'src/app/exports/interfaces/IEventPlace';
import { IEventPrice } from 'src/app/exports/interfaces/IEventPrice';
import { IHttpResponse } from 'src/app/exports/interfaces/IHttpResponse';
import { IModalTypeParams } from 'src/app/exports/interfaces/IModalTypeParams';
import { ISelectValue } from 'src/app/exports/interfaces/ISelectValue';
import { ModalType } from 'src/app/exports/types/ModalType';
import { BaoService } from 'src/app/services/bao.service';
import { EventService } from 'src/app/services/event.service';
import { MessageService } from 'src/app/services/message.service';
import { ModalService } from 'src/app/services/modal.service';
import { ScriptBuyService } from 'src/app/services/script-buy.service';
import { UserService } from 'src/app/services/user.service';

declare var $: any;

@Component({
  selector: 'div[app-buy-block]',
  templateUrl: './buy-block.component.html',
  styleUrls: ['./buy-block.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuyBlockComponent implements OnInit {
  modal: ModalType = 'buy';
  format = 'dd.MM.yyyy';
  locale = 'en-US';
  time = 'HH:mm';

  @Output() data = new EventEmitter<ModalType>();
  @Input() date: IDateItem;
  @Input() event: IEventItem;
  @Input() params: IModalTypeParams;
  $id = RandomStringFunction();
  tab: string = 'main';
  times: IDateItem[] = [];
  areas: ISelectValue[] = [];
  area: number = 0;

  params$: any = {};

  prices: IEventPrice[] = [];
  categories: IEventAreaCategory[] = [];

  ch_categories: number[] = [];
  places: any[] = [];
  cats: any[] = [];
  sins: any[] = [];
  isTooltipVisible: boolean = false;


  constructor(
    private modal$: ModalService,
    private message$: MessageService,
    private bao$: BaoService,
    private user$: UserService,
    private event$: EventService,
    public manager$: ScriptBuyService,
    private router$: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.manager$.setId(this.$id);
    this.manager$.getting.subscribe((data: any) => {
      let i = 0;
      const int = setTimeout(() => {
        $('.wrap-buy-block .scene__main').click();
        i++;
        if (i == 1) {
          clearInterval(int);
        }
      }, 100);
      let id = data.place_id;
      let price_id = data.price_id;

      if (data.action == 'add' || data.action == 'remove') {
        const places = this.places;

        if (places.filter(i => i.place_id == id && i.price_id == price_id).length > 0) {
          let key: any = false;
          places.map((i, k) => {
            if (i.place_id == id && i.price_id == price_id)
              key = k;
          });

          if (key >= 0) {
            places.splice(key, 1);
          }
        } else {
          places.push({ place_id: id, price_id });
        }

        this.places = places;
        this.manager$.count = this.places.length + this.sins.length;
        this.cdr.detectChanges();
      }
    });
  }


  ngOnInit(): void {
    this.getAreas();
    if (this.areas.length > 0) {
      this.area = this.areas[0].value;
      this.cdr.detectChanges();
    }

    const params: any = { event: this.event.id, user: false };

    if (this.area)
      params['schemes'] = this.area;

    this.init(params);
    // this.manager$.setPrice(this.prices, this.area);
    this.manager$.clicking();
    this.catTooltip();
  }


  init(params = {}) {
    this.getTime(this.date);
    // if (this.areas.length > 0) {
    //   this.setTab('scene');
    // }
  }

  getArea(area: number) {
    this.area = area;
    this.cdr.detectChanges();
    const params: any = { event: this.event.id, user: false };

    if (this.area)
      params['schemes'] = this.area;

    this.init(params);
  }

  getAreas() {
    const arr: ISelectValue[] = [];
    this.event.area.schems.map(area => {
      if (this.date.id && area.dates.includes(this.date.id)) {
        arr.push({
          name: area.name,
          value: area.id
        });
      }
    });
    this.areas = arr;
    this.cdr.detectChanges();
  }


  getScheme() {
    const scheme: IEventAreaSchems = this.event.area.schems.filter(i => i.id == this.area)[0];
    return scheme;
  }

  toDateTimestamp(date: any) {
    return new Date(date).valueOf();
  }

  toDateTime(date: any) {
    return formatDate(date, this.time, this.locale);
  }


  getTime(date: IDateItem) {
    this.event$.getSellInfo({ date: this.date.id, event: this.event.id, scheme: this.area })
      .subscribe((response: IHttpResponse) => {
        if (response.results && typeof response.results == "object") {
          if (response.results.hasOwnProperty('prices') && Array.isArray(response.results.prices)) {
            this.prices = response.results.prices;
            this.cdr.detectChanges();
            this.manager$.setPrice(this.prices, this.area);
          }
          if (response.results.hasOwnProperty('categories') && Array.isArray(response.results.categories))
            this.categories = response.results.categories;
          this.cdr.detectChanges();
          this.manager$.setCategory(this.categories, this.area);
          this.manager$.setTooltips(this.prices, this.categories);
        }
      });
    this.manager$.setPrice(this.prices, this.area);
  }

  getNow() {
    return Date.now();
  }

  getUpdated(event: any) {
    if (this.prices.length > 0) {
      this.manager$.setPrice(this.prices, this.area);
      this.manager$.setCategory(this.categories, this.area);
      this.manager$.clicking();
    }
  }

  buy() {
    if (this.places.length == 0 && this.cats.length == 0) {
      this.message$.handle('Не выбран не один товар!');
      return;
    }
    if (this.getTotalCount() > 10) {
      this.message$.handle('Извините, вы не можете приобрести более 10 билетов');
      return;
    }

    const timeout = setTimeout(() => {
      this.modal$.open('notification-tiny', {
        type: 'error',
        text: 'Произошла ошибка добавления товаров, если при переходе в выбранные товары не появились, пожалуйста повторите попытку или обратитесь в администрацию'
      });
    }, 10000);

    const done = new Subject();

    done.subscribe(ok => {
      clearTimeout(timeout);
      this.manager$.prices = [];
      this.message$.handle('Все товары успешно добавлены в корзину', 'success');
      this.router$.navigate(['/basket']);
    });
    const user = this.user$.currentUser;

    var i = 0;
    this.cats.map(cat => {
      let param$: any = {
        product: this.event.id,
        date: this.date.id,
        category: cat.id,
        quantity: cat.quantity,
      };

      if (typeof user !== 'object')
        param$.f_user = this.bao$.getFUserKey();


      this.bao$.addBasket(param$).subscribe(res => {
        i++;
        if (this.cats.length == i && this.places.length == 0)
          done.next(true);
      });
    });

    var j = 0;
    this.places.map(pl => {
      let param$: any = {
        product: this.event.id,
        date: this.date.id,
        place: pl.place_id,
        price: pl.price_id,
        quantity: 1
      };

      if (typeof user !== 'object')
        param$.f_user = this.bao$.getFUserKey();


      this.bao$.addBasket(param$).subscribe(res => {
        j++;
        if (this.places.length == j)
          done.next(true);
      });

    });
  }

  getCategoryPrice(cat: IEventAreaCategory) {
    return cat.price;
  }

  getQuantity(quantity: number, cat: IEventAreaCategory) {
    const cats = this.cats;
    if (this.getTotalCount() > 10) { this.message$.handle('Извините, вы не можете приобрести более 10 билетов'); } else {
      if (cats.filter(i => i.id == cat.id).length > 0) {
        cats.map((c, k) => {
          if (c.id == cat.id)
            cats[k].quantity = quantity;
        });
      } else {
        cats.push({ id: cat.id, quantity, name: cat.name, description: cat.description, price: cat.price, color: cat.color });
      }
      this.cats = cats;

      const transformedArray: any[] = [];

      this.cats.forEach(item => {
        for (let i = 0; i < item.quantity; i++) {
          const newItem = {
            id: item.id,
            quantity: 1,
            name: item.name,
            description: item.description,
            price: item.price,
            color: item.color
          };
          transformedArray.push(newItem);
        }
      });

      this.sins = transformedArray;
      this.manager$.count = this.places.length + this.sins.length;
      this.cdr.detectChanges();
    }

  }

  getCatPrice(cat: IEventAreaCategory, single = false) {
    let price: any = 0;
    if (this.cats.filter(i => i.id == cat.id).length > 0) {
      this.cats.map(c => {
        if (c.id == cat.id) {
          if (!single)
            price = parseInt(cat.price) * parseInt(c.quantity);
          else
            price = parseInt(cat.price);
        }
      });
    }
    return price;
  }

  getCatQuantity(cat: IEventAreaCategory) {
    let quant: any = 0;
    if (this.cats.filter(i => i.id == cat.id).length > 0) {
      this.cats.map(c => {
        if (c.id == cat.id) {
          quant = parseInt(c.quantity);
        }
      });
    }
    return quant;
  }

  getCatAvailable(cat: IEventAreaCategory) {
    let available = cat.count - cat.basket;
    let available2 = available;
    this.cats.map(c => {
      if (c.id == cat.id) {
        available2 = parseInt(c.quantity) + 10 - this.getTotalCount();
      }
    });
    return available > available2 ? available2 : available;
  }

  getPriceInfo(item: any) {
    let price: any = 0;
    this.prices.map(pr => {
      if (pr.id == item.price_id)
        price = pr;
    });
    let pr: IEventPrice = price;
    return pr;
  }

  getPlaceInfo(item: any) {
    const price = this.getPriceInfo(item);
    let place: any;
    price.place.map(pl => {
      if (pl.id == item.place_id)
        place = pl;
    });
    let pl: IEventAreaPlaces = place;
    return pl;
  }

  getTotalPrice() {
    let price: any = 0;
    this.categories.map(cat => {
      price += this.getCatPrice(cat);
    });

    this.places.map(pl => {
      const p = this.getPriceInfo(pl);
      if (p && p.price)
        price += p.price;
    });
    return price;
  }

  getTotalCount() {
    let quant: any = 0;
    this.categories.map(cat => {
      quant += this.getCatQuantity(cat);
    });

    this.places.map(pl => {
      quant++;
    });
    return quant;
  }


  cancel() {
    this.router$.navigate([this.router$.url]);
  }

  catTooltip() {
    $(document).on('click', `#${this.$id} .operating[data-category-id]`, (e: any) => {
      e.preventDefault();
      $('#cat-tooltip')
        .css({
          display: 'block',
          position: 'absolute',
          // top: e.pageY - 180,
          // left: e.pageX - 150,
        });
      this.isTooltipVisible = true;
      this.cdr.detectChanges();
    });

    $(document).click((e: any) => {
      if (!$(e.target).closest('.operating, .cat-tooltip').length) {
        $('#cat-tooltip').css('display', 'none');
        this.isTooltipVisible = false;
        this.cdr.detectChanges();
      }
    });
  }

  delPlace(place: any) {
    this.places.map((item, key) => {
      if (item.place_id == place.place_id) {
        this.places.splice(key, 1);
      }
    });
    this.manager$.count = this.places.length + this.sins.length;
    this.manager$.delPlace(place.place_id);
    this.manager$.setPrice(this.prices, this.area);
    this.cdr.detectChanges();

  }

  delCat(sin: any): void {
    const index = this.sins.indexOf(sin);
    let id = this.sins[index]['id'];
    this.cats.map((item) => {
      if (item.id == id) {
        item.quantity -= 1;
      }
    });
    if (index !== -1) {
      this.sins.splice(index, 1);
    }
    this.manager$.count = this.places.length + this.sins.length;
  }

  showCategories(type: string, id: number) {
    $('[data-category-id]').css('opacity', 0.3);
    $('[data-price-id]').css('opacity', 0.3);
    if (type === 'category') {
      $(`[data-category-id="${id}"]`).css('opacity', 1);
    }
    if (type === 'price') {
      $(`[data-price-id="${id}"]`).css('opacity', 1);
    }
  }

}