<div class="form-item">
    <div class="form-item__main">
        <div class="form-item__field">
            <div class="attach">
                <div class="attach attach__file attach__file_single">
                    <div class="attach__drag drag"
                         data-listname="listAnnouncement" 
                         (dragover)="dragover($event)" 
                         (drop)="drop($event)">
                        <div class="drag__media media">
                            <svg class="media__ico">
                                <use xlink:href="/assets/img/sprite.svg#attach"></use>
                            </svg>
                        </div>
                        <div class="drag__content">
                            <div class="wysiwyg">
                                <p>
                                    Перетащите
                                    файл
                                    сюда
                                    или
                                    нажмите
                                    Добавить
                                    файл</p>
                                <small>Максимум
                                    файлов:
                                    1 ;
                                    Максимальный
                                    размер
                                    - 5
                                    Мб</small>
                            </div>
                        </div>
                        <div class="drag__action" style="position: relative;">
                            <span class="btn">Добавить файл</span>
                            <input 
                                type="file" 
                                (change)="change($event)"
                                class="input" 
                                [id]="$id"
                                [multiple]="multiple" 
                                [disabled]="disabled"
                                style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity: 0;">
                        </div>
                    </div>
                    <div class="col_12" *ngIf="errors.length > 0">
                        <div class="col_12" style="color: red" *ngFor="let error of errors">{{ error }}</div>
                    </div>
                    <div class="attach__list listAnnouncement" *ngIf="isPhotoExist()">
                        <div class="attach__item w-100" *ngFor="let image of value | keyvalue">
                            <div class="attach__input">
                                
                            </div>
                            <div class="attach__media media">
                                <img class="media__img"
                                     [src]="getUrl(image.value)"
                                     alt="image description"/>
                            </div>
                            <div class="attach__title title">
                                <span class="title__text">
                                    {{ getParam(image.value, 'name') }}
                                </span>
                            </div>
                            <div class="attach__action action" (click)="delete(image.key)">
                                <svg class="action__ico">
                                    <use xlink:href="/assets/img/sprite.svg#basket"></use>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div><!-- //form-item -->
