<div class="group__main">
    <div class="schemes" *ngIf="item">
        <div class="schemes__list">
            <div class="row">
                <div class="col col_3 col_tab-6 col_mob-12">
                    <div data-app-schem-area-item [item]="item" (openSchemeCreateEvent)="showCreateScheme($event)"></div>
                </div>
            </div>
        </div>
    </div>
</div>