import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrapSalesPageItemComponent } from './wrap-sales-page-item.component';
import { RouterModule } from '@angular/router';
import { ImageModule } from 'src/app/components/image/image.module';



@NgModule({
  declarations: [WrapSalesPageItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    ImageModule
  ],
  exports: [WrapSalesPageItemComponent]
})
export class WrapSalesPageItemModule { }
