<div class="layout">
    <div data-app-header></div>

    <div class="layout__main">
        <!-- partial:parts/_breadcrumbs.html -->
        <!-- // ХЛЕБНЫЕ КРОШКИ -->
        <div data-app-breadcrumbs></div>
        <!-- partial -->

        <!-- СЕКЦИЯ ОБЫТИЙ -->
        <div class="layout__section section" *ngIf="item">
            <div class="section__main">
                <div class="container">
                    <div class="event">
                        <div class="row">
                            <div class="col col_12">
                                <div class="event__banner banner">
                                    <div class="banner__media media">
                                        <div data-app-image class="media__img" [src]="item.afisha.image.url"></div>
                                    </div>
                                    <div class="banner__layout">
                                        <div class="banner__header">
                                            <div class="banner__type-block">
                                                <div class="banner__type banner__type_concert type" *ngIf="item.type">
                                                    <span class="type__text">{{ item.type.name }}</span>
                                                </div>
                                            </div>
                                            <div data-app-favorite setClass="banner__favorite" [item]="item"></div>
                                        </div>
                                        <div class="banner__main">
                                            <div class="banner__content">
                                                <div class="wysiwyg">
                                                    <h1>{{ item.name }}</h1>
                                                    <!-- <p>
                                                        
                                                    </p> -->
                                                </div>
                                            </div>
                                            <div class="banner__data data">
                                                <div class="data__list">
                                                    <div class="row">
                                                        <div class="col col_5 col_big-desktop-5 col_mob-12"
                                                            *ngIf="item.start_date != 0">
                                                            <div class="data__item">
                                                                <div class="data__media">
                                                                    <svg class="media__ico">
                                                                        <use xlink:href="/assets/img/sprite.svg#date">
                                                                        </use>
                                                                    </svg>
                                                                </div>
                                                                <div class="data__main">
                                                                    <div class="wysiwyg">
                                                                        <p *ngIf="!isDateSame()">с
                                                                            {{ item.start_date | date: "dd.MM.Y" }} по
                                                                            {{ item.finish_date | date: "dd.MM.Y" }}</p>
                                                                        <p *ngIf="isDateSame()">
                                                                            {{ item.start_date | date: "dd.MM.Y" }}</p>
                                                                        <p>{{getTime(item.dates[0])}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col col_6 col_big-desktop-6 col_mob-12">
                                                            <div class="data__item">
                                                                <div class="data__media">
                                                                    <svg class="media__ico">
                                                                        <use
                                                                            xlink:href="/assets/img/sprite.svg#location">
                                                                        </use>
                                                                    </svg>
                                                                </div>
                                                                <div class="data__main">
                                                                    <div class="wysiwyg">
                                                                        <p>{{ item.area.name }}</p>
                                                                        <p>{{ item.area.city.name }},
                                                                            {{ item.area.address }}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col col_1 col_big-desktop-1 col_mob-12">
                                                            <div class="data__item">
                                                                <div class="data__media">
                                                                    <span class="age__text">{{ item.age.name }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="banner__footer" *ngIf="item.status.code == 'active' && item.start_date != 0 && item.available > 0">
                                            <div class="banner__action action">
                                                <span data-buy-btn
                                                    [data]="item"
                                                    setClass="action__btn btn modal-init"
                                                    [text]="'Купить билет'"
                                                ></span>
                                            </div>
                                        </div> -->
                                        <div class="banner__footer"
                                            *ngIf="item.status.code == 'active' && item.start_date != 0 && item.dates.length > 0 && item.available > 0">
                                            <div class="banner__action action">
                                                <span class="action__btn btn modal-init"
                                                    (click)="scrollToDateSection()">Купить билет</span>
                                            </div>
                                        </div>
                                        <div class="banner__footer"
                                            *ngIf="item.status.code == 'sold-out' || item.available <= 0 || item.dates.length <= 0">
                                            <span data-buy-btn [data]="item"
                                                setClass="action__btn btn modal-init disabled"
                                                [text]="'Билетов нет'"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col_9 col_big-desktop-8 col_mob-12">
                                <div class="event__layout">
                                    <div class="event__main">
                                        <div class="wysiwyg" [innerHTML]="setHtml()"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col_3 col_big-desktop-4 col_mob-12">
                                <aside class="event__sidebar sidebar" *ngIf="promotion">
                                    <div class="sidebar__header">
                                        <div class="sidebar__title title">
                                            <span class="title__text">Действует акция {{ getPromotionName() }}</span>
                                        </div>
                                    </div>
                                    <div class="sidebar__main">
                                        <div class="wysiwyg">
                                            <p>{{ getPromotionDescription() }}</p>
                                        </div>
                                    </div>
                                    <div class="sidebar__media">
                                        <div data-app-image src="/assets/img/content/event/event__banner01.png"
                                            class="media__img"></div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                        <div class="event__footer">
                            <div class="event__data data">
                                <div class="data__list">
                                    <div class="data__item">
                                        <div class="row" style="display: flex;">
                                            <div class="wysiwyg">
                                                <p><strong>Организатор мероприятия:</strong></p>
                                            </div>
                                            <div class="wysiwyg event-data-values">
                                                <p>{{ item.user.kor_name }} {{ item.user.legal_first_name }}
                                                    {{ item.user.legal_name }} {{ item.user.legal_last_name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="data__item" *ngIf="item.user.inn || item.user.ogrn">
                                        <div class="row" style="display: flex">
                                            <div class="wysiwyg">
                                                <p><strong>ИНН/ОГРН:</strong></p>
                                            </div>
                                            <div class="wysiwyg event-data-values">
                                                <p>
                                                    {{ item.user.inn }}
                                                    <ng-container *ngIf="item.user.ogrn">
                                                        /{{ item.user.ogrn }}
                                                    </ng-container>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="buy-section"
            *ngIf="item && item.dates.length > 0 && item.status.code == 'active' && item.start_date != 0 && item.available > 0">
            <div class="date-container" *ngIf="item.dates.length <= 1">
                <span class="title__text h3">Покупка билета на {{getDate(item.dates[0])}},
                    {{getTime(item.dates[0])}}</span>
            </div>

            <div class="date-container" *ngIf="item.dates.length > 1">
                <div class="modal__title title">
                    <span class="title__text h3" style="margin:auto">Выбор на дату {{getDate(selectedDate)}},
                        {{getTime(selectedDate)}}</span>
                </div>
                <div class="month-picker">
                    <div class="custom-dropdown" [ngClass]="{'open': isDropdownOpen}">
                        <div class="custom-dropdown-toggle" (click)="toggleDropdown()">
                            {{ selectedMonth }}
                            <svg class="media__ico">
                                <use xlink:href="/assets/img/sprite.svg#arrowBottom"></use>
                            </svg>
                        </div>
                        <ul class="custom-dropdown-menu">
                            <li *ngFor="let month of availableMonths" (click)="selectMonth(month)">{{ month }}</li>
                        </ul>
                    </div>
                </div>

                <div class="date-buttons" style="margin-bottom:1rem">
                    <button class="action__btn btn modal-init date-button"
                        [ngClass]="{'btn_active': date == selectedDate}" *ngFor="let date of filteredDates"
                        (click)="showBuyBlock(date)">
                        {{ toDateTime(date.start_date) }}
                    </button>
                </div>
            </div>
            <div class="wrap-buy-item" *ngIf="selectedDate && enabled" app-buy-block [event]="item"
                [date]="selectedDate"></div>
        </div>
        <!-- СЕКЦИЯ КАРТЫ -->
        <div class="layout__section section">
            <div class="section__header">
                <div class="container">
                    <div class="wysiwyg">
                        <h2>Место проведения</h2>
                    </div>
                </div>
            </div>
            <div class="section__main" *ngIf="item && getLatitude() && getLongtitude()">
                <div class="container">
                    <div class="row">
                        <div class="col col_9 col_big-desktop-8 col_mob-12">
                            <div style="width: 100%; height: 500px;">
                                <ya-map [center]="[getLatitude(), getLongtitude()]" [zoom]="18">
                                    <ya-placemark [geometry]="[getLatitude(), getLongtitude()]"></ya-placemark>
                                </ya-map>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section__place">
                <div class="row">
                    <div class="wysiwyg event-data-values">
                        <p><strong>{{ item.area.name}}</strong></p>
                    </div>
                    <div class="wysiwyg event-data-values">
                        <p>г. {{ item.area.city.name}}, {{ item.area.address }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="event__footer" *ngIf="item">
            <div class="event__data data">
                <div class="data__list">
                    <div class="data__item">
                    </div>
                </div>
            </div>
        </div>
        <div data-app-event-gallery *ngIf="item" [items]="getGallery()"></div>

    </div>

    <div data-app-footer></div>
</div>