import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-about-menu',
  templateUrl: './about-menu.component.html',
  styleUrls: ['./about-menu.component.css']
})
export class AboutMenuComponent implements OnInit {
  isFooterActive = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
