<header class="layout__header header">
    <!-- Логотип -->
    <span data-app-header-logo class="header__logo logo"></span>

    <!-- Навигация -->
    <span data-app-top-menu class="header__nav nav header_mob"></span>

    <!-- Поиск -->
    <span data-app-searchmain class="header__search search"></span>

    <!-- Корзина -->
    <span data-app-basket class="header__cart cart"></span>

    <!-- Аватар -->
    <span data-app-account-link class="header__avatar avatar"></span>

    <!-- Бургер -->
    <div class="header__burger burger">
      <span class="burger__item"></span>
      <span class="burger__item"></span>
      <span class="burger__item"></span>
    </div>
</header><!-- //header -->
<!-- partial -->
