import { MessageService } from "src/app/services/message.service";
import { IDateItem } from "../interfaces/IDateItem";
import { IPriceAndPlaceReference } from "../interfaces/IPriceAndPlaceReference";
import { IEventPrice } from "../interfaces/IEventPrice";
import { IEventAreaCategory } from "../interfaces/IEventAreaCategory";
import { ICategoriesReference } from "../interfaces/ICategoriesReference";

declare var $: any;

interface ILocalItem {
  type?: string;
  id?: number,
  color?: string;
}

export class ScriptManagerSceneCreate {
  id: ILocalItem = {};
  values: IPriceAndPlaceReference[] = [];
  cats: ICategoriesReference[] = [];
  categories: number[] = [];
  dates: IDateItem[] = [];

  constructor(
    private $id: string,
    private message$: MessageService
  ) {
    $(document).on('click', `#${this.$id} .custom__scene_categories input`, (el: any) => {
      const $this = $(el.target);
      const $id = parseInt($this.val());

      if (this.id.id == $id) {
        $this.prop('checked', false);
        this.id = {};
      } else {
        this.id = {
          type: $this.attr('name'),
          id: $id,
          color: $this.attr('data-color')
        };
      }

      console.log('this.id', this.id);

      if ($this.attr('name') == 'date') {
        const date: IDateItem = JSON.parse($this.val());
        if (typeof date == "object" && date.hasOwnProperty('id')) {
          if (this.dates.filter(item => item.id == date.id).length == 0) {
            this.dates.push(date);
          } else {
            this.dates.map((item, key) => {
              if (item.id == date.id) {
                this.dates.splice(key, 1);
              }
            });
          }
        }
      }

      if ($this.attr('name') == 'category') {
        const category: number = JSON.parse($this.val());
        if (category > 0) {
          if (this.categories.filter(item => item == category).length == 0) {
            this.categories.push(category);
          } else {
            this.categories.map((item, key) => {
              if (item == category) {
                this.categories.splice(key, 1);
              }
            });
          }
        }
      }
    });


    $(document).on('click', `#${this.$id} .operating`, (el: any) => {
      const $this = $(el.target);
      const priceid = parseInt($this.attr('data-place-id'));
      const catid = parseInt($this.attr('data-category'));
      if ($this[0].hasAttribute('data-price-id') && $this.attr('data-price-id') > 0) {
        this.values.map((i, k) => {
          if (i.place_id == priceid && i.price_id == parseInt($this.attr('data-price-id'))) {
            this.values.splice(k, 1);
            $this.css('fill', '');
            console.log(this.values);
          }
        });
      }
      if ($this[0].hasAttribute('data-category-id') && $this.attr('data-category-id') > 0) {
        this.cats.map((i, k) => {
          if (i.place_id == catid && i.category_id == parseInt($this.attr('data-category-id'))) {
            this.cats.splice(k, 1);
            $this.css('fill', '');
          }
        });
      }
      if (this.id.type == 'category') {
        console.log($this.val());
        if (
          this.id.type == 'category' &&
          this.id.id &&
          this.id.id > 0 &&
          catid > 0
        ) {
          $this.css('fill', this.id.color);
          $this.attr('data-category-id', this.id.id);
          this.cats.push({
            category_id: this.id.id,
            place_id: catid
          });
        }
      }
      if (this.id.type == 'price') {
        $this.css('fill', '');
        if (
          this.id.type == 'price' &&
          this.id.id &&
          this.id.id > 0 &&
          priceid > 0
        ) {
          $this.css('fill', this.id.color);
          $this.attr('data-price-id', this.id.id);
          this.values.push({
            price_id: this.id.id,
            place_id: priceid
          });

        } else {
          this.message$.handle('Для присвоения данных нужно выбрать цену и нажать на соответствующее место');
        }
      }
    });
  }

  setPrice(prices: IEventPrice[], scheme_id: number) {
    prices.map((element: IEventPrice) => {
      element.place.map(place => {
        if (place.scheme.filter(i => i == scheme_id).length > 0) {
          const item = $(`#${this.$id} [data-place-id="${place.id}"]`);
          if (item.length > 0) {
            this.values.push({
              place_id: place.id,
              price_id: element.id
            });
            item.attr('data-price-id', element.id);
            item.css('fill', element.color);
          }
        }
      });

    });
  }

  setCategories(categories: IEventAreaCategory[], scheme_id: number) {
    categories.map((element: IEventAreaCategory) => {
      element.place.map(place => {
        const item = $(`#${this.$id} [data-category="${place}"]`);
        if (item.length > 0) {
          this.cats.push({
            place_id: place,
            category_id: element.id
          });
          item.attr('data-category-id', element.id);
          item.css('fill', element.color);
        }
      });

    });
  }

  setDate(data: IDateItem[]) {
    this.dates = data;
  }

  setCategory(data: IEventAreaCategory[]) {
    this.categories = data.map(item => item.id);
  }
}