<div data-app-header class="account-menu"></div>
<div class="layout layout_account">
    <main class="layout__main">
        <div data-app-sidebar class="layout__sidebar sidebar"></div>
        <!-- partial -->
        <div class="layout__content">
            <!-- СЕКЦИЯ ЗАКАЗЫ -->
            <div class="layout__section section orders" [ngClass]="{'order_active':  tab == 'main'}">
                <div class="section__header">
                    <div class="row">
                        <div class="col col_7 col_hd-desktop-6 col_big-desktop-12">
                            <div class="wysiwyg">
                                <h1>История заказов</h1>
                            </div>
                        </div>
                        <div class="col col_5 col_hd-desktop-6 col_big-desktop-12">
                            <div class="row">
                                <div class="col col_7 col_mob-12">
                                    <div class="form-item form-item_before">
                                        <div class="form-item__main">
                                            <div class="form-item__field">
                                                <input class="form-item__input" type="text" placeholder="Поиск"
                                                    (keyup)="search($event)">
                                            </div>
                                            <div class="form-item__media media">
                                                <svg class="media__ico">
                                                    <use xlink:href="/assets/img/sprite.svg#search"></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col_5 col_mob-12">
                                    <div class="form-item">
                                        <div class="form-item__main">
                                            <div class="form-item__field">
                                                <div data-app-select [values]="categories" (data)="setCategory($event)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section__main">
                    <div class="account">
                        <div class="account__orders">
                            <div class="table">
                                <table class="table_custom table_orders">
                                    <thead>
                                        <tr>
                                            <th>Название</th>
                                            <th>Дата заказа</th>
                                            <th>Место</th>
                                            <th>Количество</th>
                                            <th>Сумма</th>
                                            <th style="width: 3rem;">Действия</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr data-app-checkout-block [item]="item" *ngFor="let item of items"
                                            (openDetails)="openDetailOrder(item)"></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="layout__section section orders" [ngClass]="{'order_active':  tab == 'order'}" *ngIf="order">
                <div class="back-button" (click)="close()">
                    <svg class="media__ico">
                        <use xlink:href="/assets/img/sprite.svg#arrowLeft"></use>
                    </svg>
                    <h4 class="back-text"><strong>Детали заказа №{{order.id}}</strong></h4>
                </div>
                <div class="detail-order-event" *ngFor="let product of orderBaskets">
                    <div class="table detail-table">
                        <table class="table_custom table_orders">
                            <thead>
                                <tr>
                                    <th style="width: 40%;"></th>
                                    <th style="width: 30%;"></th>
                                    <th style="width: 30%;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="table__group group">
                                            <div class="group__media media">
                                                <div data-app-image class="media__img"
                                                    [src]="product.items[0].product.afisha.image.small">
                                                </div>
                                            </div>
                                            <div class="group__main">
                                                {{ product.items[0].product.name }}
                                                <span class="group__item group__item_300">
                                                    {{ getTypes(product.items[0]) }}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="table__group group">
                                            <div class="group__main">
                                                <div class="group__item">
                                                    {{product.items[0].date.start_date| date: "dd.MM.Y" }}</div>
                                                <p class="group__item group__item_300">
                                                    {{product.items[0].date.start_date| date: "HH:mm" }}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="table__group group">
                                            <div class="group__main">
                                                <p class="group__item group__item_300">
                                                    <strong>{{ product.items[0].product.area.name }}</strong>
                                                </p>
                                                <p class="group__item group__item_300">
                                                    {{ product.items[0].product.city.name }},
                                                    {{ product.items[0].product.area.address }}</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="tickets-block">
                            <div class="ticket-card" *ngFor="let basket of product.items">
                                <div class="ticket-info">
                                    <div class="ticket-number">№ {{basket.id}}</div>
                                    <div class="ticket-cat" *ngIf="basket.category">
                                        {{basket.category.name}}
                                    </div>
                                    <div class="ticket-place" *ngIf="basket.price">
                                        <div class="ticket-place-item">
                                            <p class="item-top">Ряд</p>
                                            <p class="item-bottom">{{extractNumber(basket.place?.row)}}</p>
                                        </div>
                                        <div class="ticket-place-item">
                                            <p class="item-top">Место</p>
                                            <p class="item-bottom">{{basket.place?.name}}</p>
                                        </div>
                                        <div class="ticket-place-item">
                                            <p class="item-top">Цена</p>
                                            <p class="item-bottom">{{basket.price.price}} р.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="group__media media">
                                    <div data-app-image class="media__img"
                                        src="/assets/img/qr/{{basket.id}}_{{order.id}}_{{product.product}}.png">
                                    </div>
                                </div>
                                <div class="download-button" (click)="downloadPDF(basket.id)">
                                    <span class="button-text">Скачать</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>