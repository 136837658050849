<div class="event__banner event__banner_second">
    <a class="banner__link" href="#" (click)="getDetail($event)">
        <div class="banner__media media">
            <div data-app-image [src]="item.image.image.large" class="media__img"></div>
        </div>
        <div class="banner__layout">
            <div class="row">
                <div class="col col_2 col_mob-12">
                    <div class="banner__sidebar">
                        <div class="banner__date date">
                            <span class="date__day">{{ item.date | date: "d" }}</span>
                            <span class="date__month">{{ getMonth(item.date | date: "M") }}</span>
                        </div>
                    </div>
                </div>
                <div class="col col_10 col_mob-12">
                    <div class="banner__header" style="display: none;">
                        <div class="banner__type banner__type_concert type">
                            <span class="type__text">Концерт</span>
                        </div>
                    </div>
                    <div class="banner__main">
                        <div class="banner__content">
                            <div class="wysiwyg">
                                <h1>{{ item.name }}</h1>
                                <p>
                                    {{ item.subtitle }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a>
</div>
