<a class="cart__link" routerLink="/basket">
    <div class="cart__media media">
      <svg class="cart__ico">
        <use xlink:href="/assets/img/sprite.svg#cart"></use>
      </svg>
    </div>
    <div class="cart__status status">
      <i class="status__ico"></i>
    </div>
</a>
