<div class="layout__section section">
    <div class="section__header">
        <div class="row">
            <div class="col col_10 col_hd-desktop-9 col_tab-12">
                <div class="wysiwyg">
                    <h1>Экспорт / импорт данных</h1>
                </div>
            </div>
            <div class="col col_2 col_hd-desktop-3 col_tab-12">
                <span class="btn modal-init" (click)="openModal()">Импорт данных</span>
            </div>
        </div>
    </div>
    <div class="section__main">
        <div class="account">
            <div class="account__import import">
                <div class="table">
                    <table class="table_custom table_import">
                        <thead>
                            <tr>
                                <th class="no-wrap">Название мероприятия</th>
                                <th class="no-wrap">Продано билетов</th>
                                <th class="no-wrap">Сумма</th>
                                <th class="no-wrap">Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <span class="group__item no-wrap">“Виктор Цой. Путь героя”</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <span class="group__item no-wrap no-wrap">2</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <span class="group__item no-wrap no-wrap">1500 р.</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <a href="#" class="btn btn_secondary no-wrap">Экспорт ШК</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <span class="group__item no-wrap">“Виктор Цой. Путь героя”</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <span class="group__item no-wrap no-wrap">2</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <span class="group__item no-wrap no-wrap">1500 р.</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <a href="#" class="btn btn_secondary no-wrap">Экспорт ШК</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <span class="group__item no-wrap">“Виктор Цой. Путь героя”</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <span class="group__item no-wrap no-wrap">2</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <span class="group__item no-wrap no-wrap">1500 р.</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <a href="#" class="btn btn_secondary no-wrap">Экспорт ШК</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <span class="group__item no-wrap">“Виктор Цой. Путь героя”</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <span class="group__item no-wrap no-wrap">2</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <span class="group__item no-wrap no-wrap">1500 р.</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <a href="#" class="btn btn_secondary no-wrap">Экспорт ШК</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <span class="group__item no-wrap">“Виктор Цой. Путь героя”</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <span class="group__item no-wrap no-wrap">2</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <span class="group__item no-wrap no-wrap">1500 р.</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table__group group">
                                        <div class="group__main">
                                            <a href="#" class="btn btn_secondary no-wrap">Экспорт ШК</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
