<div class="range" [id]="$id">
    <div class="range__form">
        <div class="range__item range__item_min">
            <span class="range__title">ОТ</span>
            <input class="range__input form-item__input range__input_min"
                   type="text"
                   placeholder="0">
        </div>
        <div class="range__item range__item_max">
            <span class="range__title">ДО</span>
            <input class="range__input form-item__input range__input_max"
                   type="text"
                   placeholder="0">
        </div>

    </div>
    <div class="range__slider"></div>
</div>
