<div data-app-loader *ngIf="date == null"></div>
<div class="buy__content">
    <div class="buy__formular">
        <div class="scene">
            <div class="formular">
                <div data-app-select [values]="areas" (data)="getArea($event)" *ngIf="areas.length > 1"></div>
                <div class="form-item">
                    <div class="scene__categories categories" *ngIf="areas.length > 0">
                        <div class="categories__list justify-center">
                            <div class="categories__item" *ngFor="let price of prices">
                                <label class="categories__label">
                                    <div class="buy_categories__main" (click)="showCategories('price', price.id)">
                                        <span class="buy_categories__background"
                                            [ngStyle]="{'background-color': price.color}"></span>
                                        <span class="buy_categories__title">{{ price.price }} р.</span>
                                    </div>
                                </label>
                            </div>
                            <div class="categories__item" *ngFor="let cat of categories">
                                <label class="categories__label" [attr.cat-label-mark]="cat.id">
                                    <div class="buy_categories__main" (click)="showCategories('category', cat.id)">
                                        <span class="buy_categories__background"
                                            [ngStyle]="{'background-color': cat.color}"></span>
                                        <span class="buy_categories__title">{{ cat.name }} - {{ cat.price }} р.</span>
                                    </div>
                                </label>
                            </div>
                            <div class="categories__item">
                                <label class="categories__label">
                                    <div class="buy_categories__main">
                                        <span class="buy_categories__background"
                                            [ngStyle]="{'background-color': '#00ba34'}"></span>
                                        <span class="buy_categories__title">Ваш выбор</span>
                                    </div>
                                </label>
                            </div>
                            <div class="categories__item">
                                <label class="categories__label">
                                    <div class="buy_categories__main">
                                        <span class="buy_categories__background"
                                            [ngStyle]="{'background-color': '#727272'}"></span>
                                        <span class="buy_categories__title">Место занято</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div data-app-scheme-block *ngIf="getScheme()" [html]="getScheme().schem" [identical]="$id"
                (update)="getUpdated($event)">
            </div>
        </div><!-- //item -->
        <table class="table_custom table_events" *ngIf="places.length > 0 || categories.length > 0">
            <thead>
                <tr *ngIf="areas.length==0">
                    <th style="width: 70%;">
                        Тип билета
                    </th>
                    <th style="width: 15%;">
                        Стоимость
                    </th>
                    <th style="width: 15%;">
                        Количество
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let cat of categories">
                    <!-- <tr *ngIf="ch_categories.includes(cat.id) || areas.length==0"> -->
                    <tr *ngIf="areas.length==0">
                        <td>
                            <p><strong>{{ cat.name }}</strong></p>
                            <p *ngIf="cat.description">{{ cat.description }}</p>
                        </td>
                        <td>
                            <p><strong>{{ getCategoryPrice(cat) }} р.</strong></p>
                        </td>
                        <td>
                            <div class="form-item">
                                <div class="form-item__main">
                                    <div class="form-item__field">
                                        <div data-app-counter [value]="getCatQuantity(cat)"
                                            [max_length]="getCatAvailable(cat)" (data)="getQuantity($event, cat)"></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <div class="buy-line">
            <tfoot>
                <tr>
                    <div class="buy-sum-block">
                        <div class="flex-line">
                            <div class="place-card-container" *ngFor="let item of places">
                                <div class="card">
                                    <div class="card-body">
                                        <p>
                                            <strong>{{ getPlaceInfo(item).row }},
                                                {{ getPlaceInfo(item).name }} место</strong>
                                        </p>
                                        <p>
                                            <label class="categories__label">
                                                <div class="buy_categories__main">
                                                    <span class="buy_categories__background"
                                                        [ngStyle]="{'background-color': getPriceInfo(item).color}"></span>
                                                    <span class="buy_categories__title">{{ getPriceInfo(item).price }}
                                                        р.</span>
                                                </div>
                                            </label>
                                        </p>
                                        <a class="close__button" (click)="delPlace(item)">
                                            <svg class="action__ico">
                                                <use xlink:href="/assets/img/sprite.svg#closeSecond"></use>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngFor="let sin of sins">
                                <div class="place-card-container" *ngIf="sin.quantity > 0">
                                    <div class="card">
                                        <div class="card-body">
                                            <p>
                                                <strong>{{ sin.name }}</strong>
                                            </p>
                                            <p>
                                                <label class="categories__label">
                                                    <div class="buy_categories__main">
                                                        <span class="buy_categories__background"
                                                            [ngStyle]="{'background-color': sin.color}"></span>
                                                        <span class="buy_categories__title">{{ sin.price }} р.</span>
                                                    </div>
                                                </label>
                                            </p>
                                            <a class="close__button" (click)="delCat(sin)">
                                                <svg class="action__ico">
                                                    <use xlink:href="/assets/img/sprite.svg#closeSecond"></use>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </tr>
            </tfoot>
            <div class="buy-right-section">
                <div class="sum-text">
                    <div class="bilets-info">Билетов: {{ getTotalCount() }}</div>
                    <div>Итого: {{ getTotalPrice() }} р.</div>
                </div>
                <div class="buy__button">
                    <div class="buttons__list">
                        <div class="buttons__item" style="margin: auto">
                            <button class="btn" (click)="buy()">Купить билеты</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="dark-overlay" *ngIf="isTooltipVisible"></div>

    <div id="cat-tooltip" class="cat-tooltip" style="display: none;">
        <table class="table_custom table_events cat_table" *ngIf="places.length > 0 || categories.length > 0">
            <tbody>
                <ng-container *ngFor="let cat of categories">
                    <!-- <tr *ngIf="ch_categories.includes(cat.id) || areas.length==0"> -->
                    <tr>
                        <td>
                            <p><strong>{{ cat.name }}</strong></p>
                            <p *ngIf="cat.description" style="text-wrap: auto;">{{ cat.description }}</p>
                        </td>
                        <td>
                            <p><strong>{{ getCategoryPrice(cat) }} р.</strong></p>
                        </td>
                        <td>
                            <div class="form-item">
                                <div class="form-item__main">
                                    <div class="form-item__field">
                                        <div data-app-counter [value]="getCatQuantity(cat)"
                                            [max_length]="getCatAvailable(cat)" (data)="getQuantity($event, cat)"></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>