import { Component, OnInit, Input } from '@angular/core';
import { IEventItem } from '../../../../../exports/interfaces/IEventItem';
import { ISelectValue } from 'src/app/exports/interfaces/ISelectValue';
import { STATIC_EVENTS, STATIC_EVENT_PARAMS, STATIC_EVENT_RESPONSE, TAGS_LIST } from 'src/app/exports/constans';
import { UserService } from 'src/app/services/user.service';
import { IUserItem } from 'src/app/exports/interfaces/IUserItem';

@Component({
  selector: '[data-app-wrap-filter-product]',
  templateUrl: './wrap-filter-product.component.html',
  styleUrls: ['./wrap-filter-product.component.css']
})
export class WrapFilterProductComponent implements OnInit {

  items: IEventItem[] = [];
  user: IUserItem;
  isLoading: boolean = true;
  minTotalSells: number;
  maxTotalSells: number;
  originalItems: IEventItem[] = [];

  response = STATIC_EVENT_RESPONSE;
  params = STATIC_EVENT_PARAMS;
  elements = STATIC_EVENTS;

  ordering: ISelectValue[] = [
    {
      name: "По дате начала мероприятия",
      value: "dates__start_date"
    },
    {
      name: "По названию",
      value: "name"
    }
  ];

  constructor(
    private user$: UserService
  ) {

    this.user$.user.subscribe(user => {
      if (user && typeof user == "object") {
        this.user = user;
      }
    });

    STATIC_EVENTS.subscribe(items => {
      if (Array.isArray(items)) {
        this.originalItems = items; // Store the original unfiltered events
        this.items = items;
      }
      let minTotal = Number.MAX_SAFE_INTEGER;
      let maxTotal = Number.MIN_SAFE_INTEGER;

      items.forEach(item => {
        if (item.sells && typeof item.sells.total === 'number') {
          const totalSells = item.sells.total;
          if (totalSells < minTotal) {
            minTotal = totalSells;
          }
          if (totalSells > maxTotal) {
            maxTotal = totalSells;
          }
        }
      });

      this.minTotalSells = minTotal;
      this.maxTotalSells = maxTotal;
      this.isLoading = false;
    });

  }

  getOrder(value: ISelectValue) {
    let params = TAGS_LIST.getValue();
    let item: any = false;
    this.ordering.map(i => {
      if (i.value == value)
        item = i;
    });

    if (item) {
      let found: any = false;
      params.map((i, k) => {
        if (i.key == 'ordering') {
          found = k;
        }
      });

      if (found === false) {
        params.push({
          name: item.name,
          value: item.value,
          key: 'ordering'
        });
      } else {
        if (params[found]) {
          params[found] = {
            name: item.name,
            value: item.value,
            key: 'ordering'
          };
        }
      }
    } else {
      params = params.filter(i => i.key != 'ordering');
    }

    TAGS_LIST.next(params);
  }

  updateTotalSellsFilter(range: number[]) {
    const minSells = isNaN(range[0]) ? 0 : range[0];
    const maxSells = isNaN(range[1]) ? Number.MAX_SAFE_INTEGER : range[1];
    this.items = this.originalItems.filter(item => {
      if (item.sells && typeof item.sells.total === 'number') {
        const totalSells = item.sells.total;
        return totalSells >= minSells && totalSells <= maxSells;
      }
      return false;
    });
  }

  ngOnInit(): void {

  }

}
