<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item">{{ item.name }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="badge"
                [ngClass]="{
                    badge_300: item.status.id == 1,
                    badge_400: item.status.id == 2,
                    badge_500: item.status.id == 3 || item.status.id == 4
                }"
            >{{ item.status.name }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">{{ checkouts.length }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">{{ getPrice() }} р.</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item text-center no-wrap">10%</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item text-center no-wrap">{{ item.tax }}%</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <a href="#" class="btn btn_secondary no-wrap">Расчёт</a>
        </div>
    </div>
</td>
