import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[data-app-wrap-scheme-page]',
  templateUrl: './wrap-scheme-page.component.html',
  styleUrls: ['./wrap-scheme-page.component.css']
})
export class WrapSchemePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
