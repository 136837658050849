import { Component, OnInit } from '@angular/core';
import { ICheckoutReturn } from 'src/app/exports/interfaces/ICheckoutReturn';
import { IEventItem } from 'src/app/exports/interfaces/IEventItem';
import { IHttpResponse } from 'src/app/exports/interfaces/IHttpResponse';
import { ISelectValue } from 'src/app/exports/interfaces/ISelectValue';
import { CurrentUserType } from 'src/app/exports/types/CurrentUserType';
import { BaoService } from 'src/app/services/bao.service';
import { EventService } from 'src/app/services/event.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: '[data-app-wrap-return-list-page]',
  templateUrl: './wrap-return-list-page.component.html',
  styleUrls: ['./wrap-return-list-page.component.css']
})
export class WrapReturnListPageComponent implements OnInit {

  items: ICheckoutReturn[] = [];
  user: CurrentUserType = false;
  products: ISelectValue[] = [];
  events: ISelectValue[] = [];

  constructor(
    private bao$: BaoService,
    private user$: UserService,
    private event$: EventService
  ) {
    this.bao$.returns.subscribe(items => {
      this.items = items;
      if (this.products.length == 0) {
        const arr: ISelectValue[] = [];
        this.items.map(item => {
          item.checkout.products.map(basket => {
            arr.push({
              name: `Заказ №${item.checkout.id}, запрос на возврат №${item.id}, ${basket.product.name}`,
              value: item.checkout.id
            });
          });
        });

        this.products = arr;
      }
    });

    this.user$.user.subscribe((user: any) => {
      if (user && typeof user == 'object' && 'id' in user) {
        this.user = user;
        if (user.role == 'admin') {
          this.event$.getListReturn().subscribe((response: IHttpResponse) => {
            if (Array.isArray(response.results)) {
              const events: IEventItem[] = response.results;
              const arr: ISelectValue[] = [];
              events.map(item => {
                arr.push({
                  name: item.name,
                  value: item.id
                });
              });
              
              this.events = arr;
            }
          });
        } else {
          this.event$.getList({ user: user.id }).subscribe((response: IHttpResponse) => {
            if (Array.isArray(response.results)) {
              const events: IEventItem[] = response.results;
              const arr: ISelectValue[] = [];
              events.map(item => {
                arr.push({
                  name: item.name,
                  value: item.id
                });
              });
              
              this.events = arr;
            }
          });
        }
        this.bao$.getCheckoutReturns({ orginiser: user.id });
      }
    });
  }

  ngOnInit(): void {
  }

  getCheckout(event: any) {
    if (typeof this.user == "object" && this.user.hasOwnProperty('id')) {
      this.bao$.getCheckoutReturns({
        orginiser: this.user.id,
        event
      });
    }
  }

  getCheckoutID() {
    if (this.user) {
      if (
        typeof this.user == "object" &&
        this.user.hasOwnProperty('id')
      ) {
        return this.user.id;
      }
    }
    return null;
  }

  getTotalPrice() {
    let price = 0;

    this.items.map(item => {
      if (item.checkout.total_price > 0)
        price += item.checkout.total_price;
    });

    return price;
  }

}