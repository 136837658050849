<div class="row">
    <div class="col col_10 col_tab-12">
        <div class="row row_mob-reverse">
            <div class="col col_6 col_tab-12">
                <div class="event__print print">
                    <div class="print__header">
                        <div class="print__logo">
                            <a class="logo__link" href="index.html">
                                <svg class="logo__img"
                                     xmlns="http://www.w3.org/2000/svg"
                                     width="129" height="40"
                                     viewBox="0 0 129 40" fill="none">
                                    <g clip-path="url(#clip0_1637_200312)">
                                        <path d="M77.1061 11.8399C77.1061 12.4799 77.0231 13.0399 76.7741 13.5999C76.5252 14.1599 76.1102 14.6399 75.6122 15.0399C74.6162 15.7599 73.2052 16.1599 71.3792 16.1599H64.9053V2.3999H76.1932V4.5599H67.6442V13.9999H71.2962C72.1262 14.0799 72.8732 13.8399 73.5372 13.4399C73.7862 13.2799 73.9522 13.0399 74.1182 12.7199C74.2842 12.4799 74.2842 12.1599 74.2842 11.8399C74.2842 10.4799 73.2882 9.8399 71.2132 9.8399H69.8022V7.8399H71.7942C73.5372 7.8399 74.8652 8.2399 75.7782 8.8799C76.1932 9.1999 76.6081 9.6799 76.7741 10.1599C77.0231 10.6399 77.1061 11.2799 77.1061 11.8399Z"
                                              fill="black"></path>
                                        <path d="M90.6351 5.6001V16.0801H87.9791V9.3601L82.0862 16.0801H79.5962V5.6001H82.3352V12.4001L88.2281 5.6001H90.6351Z"
                                              fill="black"></path>
                                        <path d="M103.998 5.6001V16.0801H101.342V7.8401H97.3579L97.2749 9.8401C97.2749 10.9601 97.1089 12.1601 96.8599 13.2801C96.6939 14.0801 96.3619 14.8801 95.8639 15.5201C95.6149 15.7601 95.2829 16.0001 94.9509 16.1601C94.6189 16.3201 94.2039 16.4001 93.8719 16.3201C93.3739 16.3201 92.8759 16.2401 92.4609 16.0801H92.3779V16.0001L92.5439 13.9201H92.6269C92.7929 13.9201 92.9589 14.0001 93.1249 14.0001C93.3739 14.0001 93.5399 14.0001 93.7889 13.8401C93.9549 13.7601 94.1209 13.6001 94.2869 13.4401C94.5359 13.0401 94.7019 12.5601 94.7849 12.0801C94.8679 11.5201 94.9509 10.6401 94.9509 9.7601L95.1169 5.6801L103.998 5.6001Z"
                                              fill="black"></path>
                                        <path d="M117.693 10.9599C117.693 9.99994 117.444 8.95994 116.946 8.07994C116.448 7.27994 115.784 6.55994 114.954 6.15994L114.871 6.23994L114.954 6.15994C114.041 5.67994 113.045 5.43994 112.049 5.43994C111.053 5.43994 109.974 5.67994 109.061 6.15994L109.144 6.23994L109.061 6.15994C108.231 6.63994 107.484 7.27994 106.986 8.07994L107.069 8.15994L106.986 8.07994C106.488 8.95994 106.239 9.91994 106.239 10.8799C106.239 11.8399 106.488 12.7999 106.986 13.6799L107.069 13.5999L106.986 13.6799C107.484 14.4799 108.231 15.1999 109.144 15.5999C110.057 15.9999 111.053 16.2399 112.049 16.2399C113.045 16.3199 114.041 16.1599 114.954 15.8399V15.7599V15.8399L114.871 15.7599L114.954 15.8399C115.701 15.5999 116.365 15.1199 116.863 14.5599L116.946 14.4799L116.863 14.3999L115.452 12.8799L115.369 12.7999L115.286 12.8799C114.954 13.1999 114.456 13.5199 113.958 13.6799C113.46 13.8399 112.962 13.9199 112.381 13.9199V14.0799V13.9199C111.551 13.9199 110.721 13.7599 110.057 13.2799C109.476 12.8799 109.144 12.3199 108.978 11.6799H117.361H117.444V11.5999H117.278H117.444C117.693 11.3599 117.693 11.1199 117.693 10.9599ZM112.049 7.59994C112.796 7.59994 113.46 7.83994 114.041 8.31994C114.539 8.71994 114.871 9.27994 114.954 9.91994H109.061C109.144 9.27994 109.476 8.71994 110.057 8.31994C110.555 7.83994 111.302 7.59994 112.049 7.59994V7.43994V7.59994Z"
                                              fill="black"></path>
                                        <path d="M128.649 5.6001V7.8401H124.831V16.0801H122.175V7.8401H118.357V5.6001H128.649Z"
                                              fill="black"></path>
                                        <path d="M73.3715 25.6001V27.7601H69.5535V36.0801H66.8975V27.7601H62.9966V25.6001H73.3715Z"
                                              fill="black"></path>
                                        <path d="M85.6549 25.6L80.509 36.88C80.011 38 79.43 38.8 78.766 39.28C78.019 39.76 77.189 40 76.2761 40C75.6951 40 75.1971 39.92 74.6161 39.76C74.1181 39.6 73.7031 39.36 73.2881 39.04L74.3671 37.12L74.4501 37.2C74.6991 37.44 74.9481 37.6 75.2801 37.68C75.6121 37.84 75.9441 37.84 76.2761 37.84C76.691 37.84 77.023 37.76 77.355 37.52C77.687 37.2 78.019 36.88 78.185 36.4L78.351 36L73.4541 25.6H76.1931L79.596 33.12L82.999 25.52L85.6549 25.6Z"
                                              fill="black"></path>
                                        <path d="M96.1132 25.6001V27.7601H92.2952V36.0801H89.6392V27.7601H85.7383V25.6001H96.1132Z"
                                              fill="black"></path>
                                        <path d="M35.6896 0V5.04L0 10.72V5.68L35.6896 0Z"
                                              fill="black"></path>
                                        <path d="M35.5234 21.2798C33.4484 19.3598 30.7095 18.2398 27.8875 18.2398H25.3145V23.4398H27.8875C29.2985 23.4398 30.7095 23.9998 31.7055 24.9598C32.7014 25.9198 33.2824 27.1998 33.2824 28.5598C33.2824 29.1998 33.1164 29.9198 32.8674 30.4798C32.6184 31.1198 32.2035 31.6798 31.7055 32.1598C31.2075 32.6398 30.6265 33.0398 29.9625 33.2798C29.2985 33.5198 28.6345 33.6798 27.8875 33.6798H17.9276V13.2798L11.9517 14.2398V38.8798H17.6786H27.9705C30.8755 38.8798 33.6144 37.7598 35.6064 35.8398C37.6814 33.9198 38.7604 31.2798 38.7604 28.5598C38.7604 25.8398 37.5984 23.1998 35.5234 21.2798Z"
                                              fill="black"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1637_200312">
                                            <rect width="128.649"
                                                  height="40"
                                                  fill="black"></rect>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        </div>
                        <div class="print__phone phone">
                            <span class="phone__text">+9 999 99 9999</span>
                        </div>
                    </div>
                    <div class="print__options options">
                        <div class="options__list">
                            <div class="options__item">
                                <span class="options__title">№ билета:</span>
                                <span class="options__value">99999999</span>
                            </div>
                            <div class="options__item">
                                <span class="options__title">№: Заказа</span>
                                <span class="options__value">99999999</span>
                            </div>
                            <div class="options__item">
                                <span class="options__title">Покупатель:</span>
                                <span class="options__value">Константинопольский Константин Константинович</span>
                            </div>
                            <div class="options__item">
                                <span class="options__title">Дата оформления:</span>
                                <span class="options__value">99.99.9999</span>
                            </div>
                            <div class="options__item">
                                <span class="options__title">Стоимость:</span>
                                <span class="options__value">9999 р.</span>
                            </div>
                        </div>
                    </div>


                    <div class="print__main">
                        <div class="row">
                            <div class="col col_3 col_mob-12">
                                <div class="print__group">
                                    <div class="print__type print__type_concert type">
                                        <span class="type__text">Тип</span>
                                    </div>
                                    <div class="print__age age">
                                        <span class="age__text">99+</span>
                                    </div>
                                </div>

                            </div>
                            <div class="col col_6 col_mob-12">
                                <div class="print__group">
                                <div class="print__title title">
                                    <span class="title__text">“Название мероприятия”</span>
                                </div>
                                <div class="print__subtitle subtitle">
                                    <span class="subtitle__text">Адрес проведения мероприятия</span>
                                </div>
                                </div>
                            </div>
                            <div class="col col_3 col_mob-12">
                                <div class="print__group">
                                <div class="print__code code">
                                    <div class="code__img" data-app-image src="/assets/img/content/tickets/tickets__img01.jpg"></div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form__group group" *ngIf="getImage()">
                        <div class="group__header">
                            <div class="wysiwyg">
                                <h3>Изображение афиши</h3>
                            </div>
                        </div>
                        <div class="group__main">
                            <div class="form-item">
                                <div class="form-item__main">
                                    <div class="form-item__field">
                                        <img [src]="getImage()" class="media__img">
                                    </div>
                                </div>
                            </div><!-- //form-item -->
                        </div>
                    </div>
                    <div class="form__group group" *ngIf="attention">
                        <div class="group__header">
                            <div class="wysiwyg">
                                <h3>Блок “Внимание”</h3>
                            </div>
                        </div>
                        <div class="group__main">
                            <div class="form-item">
                                <div class="form-item__main">
                                    <div class="form-item__field">
                                        <div class="attach">
                                            <div class="attach attach__file attach__file_single">
                                                <div class="attach__drag drag"
                                                     data-listname="listPoster" [innerHTML]="setText(attention)" style="flex-direction: inherit; min-height: 0; text-align: left;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><!-- //form-item -->
                        </div>
                    </div>
                    <div class="form__group group" *ngIf="returning">
                        <div class="group__header">
                            <div class="wysiwyg">
                                <h3>Организатор, продавец, запреты,
                                    инструкция по складыванию</h3>
                            </div>
                        </div>
                        <div class="group__main">
                            <div class="form-item">
                                <div class="form-item__main">
                                    <div class="form-item__field">
                                        <div class="attach">
                                            <div class="attach attach__file attach__file_single">
                                                <div class="attach__drag drag"
                                                     data-listname="listPoster" [innerHTML]="setText(returning)" style="flex-direction: inherit; min-height: 0;  text-align: left;">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><!-- //form-item -->
                        </div>
                    </div>

                    <div class="form__group group" *ngIf="description">
                        <div class="group__header">
                            <div class="wysiwyg">
                                <h3>О событии</h3>
                            </div>
                        </div>
                        <div class="group__main">
                            <div class="form-item">
                                <div class="form-item__main">
                                    <div class="form-item__field">
                                        <div class="attach">
                                            <div class="attach attach__file attach__file_single">
                                                <div class="attach__drag drag"
                                                     data-listname="listPoster" [innerHTML]="setText(description)" style="flex-direction: inherit; min-height: 0;  text-align: left;">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><!-- //form-item -->
                        </div>
                    </div>
                </div> 
            </div>
            <div class="col col_6 col_tab-12">
                <div class="formular">
                    <form [formGroup]="form">
                        <fieldset>
                            <div class="form__group group">
                                <div class="group__header">
                                    <div class="wysiwyg">
                                        <h3>Изображение афиши</h3>
                                    </div>
                                </div>
                                <div class="group__main">
                                    <div class="form-item">
                                        <div class="form-item__main">
                                            <div class="form-item__field">
                                                <div data-app-photo-component
                                                    [defaultValue]="defaultValue"
                                                    [submitSubject]="formSubmit"
                                                    setClass="form-item__input"
                                                    [inputErrors]="errors"
                                                    [c]="form.controls.image"
                                                    formControlName="image"
                                                >
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- //form-item -->
                                </div>
                            </div>
                            <div class="form__group group">
                                <div class="group__header">
                                    <div class="wysiwyg">
                                        <h3>Блок “Внимание”</h3>
                                    </div>
                                </div>
                                <div class="group__main">

                                    <div class="form-item">
                                        <div class="form-item__main">
                                            <div class="custom-check">
                                                <div data-app-input-checkbox
                                                    [defaultValue]="defaultValue"
                                                    [submitSubject]="formSubmit"
                                                    [inputErrors]="errors"
                                                    [c]="form.controls.is_attention"
                                                    placeholder="По стандарту"
                                                    formControlName="is_attention"
                                                >
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- //form-item -->
                                    <div class="form-item">
                                        <div class="form-item__header">
                                            <label class="form-item__title"
                                                   for="event__status">Текст
                                                в
                                                блоке</label>
                                        </div>
                                        <div class="form-item__main">
                                            <div class="form-item">
                                                <div class="form-item__main">
                                                    <div class="form-item__field">
                                                        <div data-app-input
                                                            [defaultValue]="defaultValue"
                                                            [submitSubject]="formSubmit"
                                                            setClass="form-item__input"
                                                            [inputErrors]="errors"
                                                            [c]="form.controls.attention"
                                                            placeholder="Введите данные"
                                                            formControlName="attention"
                                                            type="textarea"
                                                        >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- //form-item -->
                                        </div>
                                    </div><!-- //form-item -->
                                </div>
                            </div>


                            <div class="form__group group">
                                <div class="group__header">
                                    <div class="wysiwyg">
                                        <h3>Правила возврата</h3>
                                    </div>
                                </div>
                                <div class="group__main">

                                    <div class="form-item">
                                        <div class="form-item__main">
                                            <div class="custom-check">
                                                <div data-app-input-checkbox
                                                    [defaultValue]="defaultValue"
                                                    [submitSubject]="formSubmit"
                                                    [inputErrors]="errors"
                                                    [c]="form.controls.is_returning"
                                                    placeholder="По стандарту"
                                                    formControlName="is_returning"
                                                >
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- //form-item -->
                                    <div class="form-item">
                                        <div class="form-item__header">
                                            <label class="form-item__title"
                                                   for="event__status">Текст
                                                в
                                                блоке</label>
                                        </div>
                                        <div class="form-item__main">
                                            <div class="form-item">
                                                <div class="form-item__main">
                                                    <div class="form-item__field">
                                                        <div data-app-input
                                                            [defaultValue]="defaultValue"
                                                            [submitSubject]="formSubmit"
                                                            setClass="form-item__input"
                                                            [inputErrors]="errors"
                                                            [c]="form.controls.returning"
                                                            placeholder="Введите данные"
                                                            formControlName="returning"
                                                            type="textarea"
                                                        >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- //form-item -->

                                        </div><!-- //form-item -->
                                    </div>

                                </div>
                            </div>


                            <div class="form__group group">
                                <div class="group__header">
                                    <div class="wysiwyg">
                                        <h3>О событии</h3>
                                    </div>
                                </div>
                                <div class="group__main">

                                    <div class="form-item">
                                        <div class="form-item__main">
                                            <div class="custom-check">
                                                <div data-app-input-checkbox
                                                    [defaultValue]="defaultValue"
                                                    [submitSubject]="formSubmit"
                                                    [inputErrors]="errors"
                                                    [c]="form.controls.is_description"
                                                    placeholder="По стандарту"
                                                    formControlName="is_description"
                                                >
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- //form-item -->
                                    <div class="form-item">
                                        <div class="form-item__header">
                                            <label class="form-item__title"
                                                   for="event__status">Текст
                                                в
                                                блоке</label>
                                        </div>
                                        <div class="form-item__main">
                                            <div class="form-item">
                                                <div class="form-item__main">
                                                    <div class="form-item__field">
                                                        <div data-app-input
                                                            [defaultValue]="defaultValue"
                                                            [submitSubject]="formSubmit"
                                                            setClass="form-item__input"
                                                            [inputErrors]="errors"
                                                            [c]="form.controls.description"
                                                            placeholder="Введите данные"
                                                            formControlName="description"
                                                            type="textarea"
                                                        >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- //form-item -->
                                        </div>
                                    </div><!-- //form-item -->
                                </div>

                                <div class="group__footer">
                                    <div class="form-item">
                                        <div class="form-item__main">
                                            <button 
                                                type="button"
                                                class="btn"
                                                (click)="save()"
                                            >
                                                Сохранить
                                                шаблон
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col col_12">
                <div class="form-item">
                    <div class="form-item__main">
                        <button type="button" class="btn" (click)="complete()">Сохранить
                            изменения
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
