import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParamsReturnPageItemComponent } from './params-return-page-item.component';



@NgModule({
  declarations: [ParamsReturnPageItemComponent],
  imports: [
    CommonModule
  ],
  exports: [ParamsReturnPageItemComponent]
})
export class ParamsReturnPageItemModule { }
