import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrapMainOrgPageComponent } from './wrap-main-org-page.component';
import { WrapFilterProductModule } from '../wrap-filter-product/wrap-filter-product.module';



@NgModule({
  declarations: [WrapMainOrgPageComponent],
  imports: [
    CommonModule,
    WrapFilterProductModule,
  ],
  exports: [WrapMainOrgPageComponent]
})
export class WrapMainOrgPageModule { }
