<div class="layout__section section">
    <div class="section__header">
        <div class="wysiwyg">
            <h1>Сортировка мероприятий</h1>
        </div>
    </div>
    <div class="section__main">
        <div class="account">
            <div class="account__statistics">
                <div class="tabs__item">
                    <div data-app-wrap-filter-product></div>
                </div><!-- //tabs-body__item -->
            </div>
        </div>
    </div>
</div>
