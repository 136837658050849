import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { IBasketItem } from 'src/app/exports/interfaces/IBasketItem';
import { ICheckoutItem } from 'src/app/exports/interfaces/ICheckoutItem';
import { IGroupedItem } from 'src/app/exports/interfaces/IGroupedItem';
import { IHttpResponse } from 'src/app/exports/interfaces/IHttpResponse';
import { BaoService } from 'src/app/services/bao.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'tr[data-app-checkout-block]',
  templateUrl: './checkout-block.component.html',
  styleUrls: ['./checkout-block.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CheckoutBlockComponent implements OnInit {

  @Input() item: ICheckoutItem;
  groupedItems: IGroupedItem[] = [];
  @Output() openDetails = new EventEmitter();

  constructor(
    private bao$: BaoService,
    private message$: MessageService,
    private router$: Router
  ) {

  }

  ngOnInit(): void {
    this.groupedItems = this.groupBasketItemsByProduct(this.item.products);
  }

  getTypes(item: IBasketItem) {
    return item.product.type.name;
  }

  getQuantity() {
    let quant = 0;
    this.item.products.map(i => {
      quant += i.quantity;
    });

    return quant;
  }

  groupBasketItemsByProduct(items: IBasketItem[]): IGroupedItem[] {
    const groupedItems: IGroupedItem[] = [];
    items.forEach(item => {
      const existingGroup = groupedItems.find(group => group.product === item.product.id);
      if (existingGroup) {
        existingGroup.items.push(item);
      } else {
        groupedItems.push({
          product: item.product.id,
          items: [item]
        });
      }
    });
    return groupedItems;
  }

  repeatBilets(event: any) {
    event.target.disabled = true;
    this.bao$.repeatBilets(this.item.id).subscribe((response: IHttpResponse) => {
      if (response.results) {
        this.message$.handle('Билеты успешно отправлены на почту клиента', 'success');
      } else {
        this.message$.handle('Билеты не были отправлены, пожалуйста повторите попытку позже или обратитесь в администрацию');
      }
    });
  }

  refundOrder(event: any) {
    event.target.disabled = true;
    this.bao$.refundOrder(this.item.id).subscribe((response: IHttpResponse) => {
      if (response.results) {
        this.message$.handle('Запрос на возврат был успешно подан в обработку', 'success');
      } else {
        this.message$.handle('Запрос на возврат не был произведен, пожалуйста повторите попытку позже или обратитесь в администрацию');
      }
    });
  }

  getDiscount(item: IBasketItem) {
    return item.discount_price * item.quantity;
  }

  getPrice(item: IBasketItem) {
    return item.pricing * item.quantity;
  }

  getTotalQuantity(items: IBasketItem[]): number {
    return items.reduce((total, item) => total + item.quantity, 0);
  }

  getTotalDiscount(items: IBasketItem[]): number {
    return items.reduce((total, item) => total + (item.discount_price * item.quantity), 0);
  }

  getTotalPrice(items: IBasketItem[]): number {
    return items.reduce((total, item) => total + (item.pricing * item.quantity), 0);
  }

  getCategoryQuantities(items: IBasketItem[]): { categoryName: string, quantity: number; }[] {
    const categoryQuantities: { categoryName: string, quantity: number; }[] = [];

    // Create a map to store quantities for each category
    const categoryQuantityMap = new Map<string, number>();

    items.forEach(item => {
      const categoryName = item.category ? item.category.name : 'Без категории'; // Use 'Uncategorized' if no category exists
      const quantity = categoryQuantityMap.get(categoryName) || 0;
      categoryQuantityMap.set(categoryName, quantity + item.quantity);
    });

    // Convert the map to an array of objects
    categoryQuantityMap.forEach((quantity, categoryName) => {
      categoryQuantities.push({ categoryName, quantity });
    });

    return categoryQuantities;
  }

  ifCanceled(item: ICheckoutItem) {
    if (item.products.some(item => item.product.status.code === "canceled")) {
      return true;
    } else {
      return false;
    }
  }

  showDetails(order: ICheckoutItem) {
    this.openDetails.emit(order);
  }

}
