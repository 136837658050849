<div class="section__main">
    <div class="container">
        <div class="basket" *ngIf="groupedItems.length > 0">
            <div class="basket-timer" *ngIf="timeTo && timeTo >=0">{{formattedTime}}</div>
            <div class="row">
                <div class="col col_8 col_tab-12">
                    <div class="basket__main">
                        <div data-app-basket-item [group]="group" *ngFor="let group of groupedItems"></div>
                    </div>
                </div>
                <div class="col col_4 col_tab-12">
                    <div data-app-basket-sum-block [items]="items"></div>
                </div>
            </div>
        </div>
        <div class="basket" *ngIf="items.length == 0">
            <div class="basket__empty empty">
                <div class="empty__media media">
                    <img class="media__img" src="/assets/img/content/basket/basket__img01.png"
                        alt="image description" />
                </div>
                <div class="empty__main">
                    <div class="wysiwyg text-center">
                        <h2>Корзина пуста</h2>
                        <p>
                            В вашей корзине ещё нет ни одного билета
                        </p>
                    </div>
                </div>
                <div class="empty__action action">
                    <a class="action__btn btn" routerlink="#/events" href="https://bilet-tut.ru/">Найти билет</a>
                </div>
            </div>
        </div>
    </div>
</div>