<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item">{{ item.name }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">
                {{ item.value }} р.
                <span *ngIf="item.point === 'Точная сумма'">р.</span>
                <span *ngIf="item.point === 'Процент'">%</span>
            </span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">{{ item.start_date | date:"dd.M.y" }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">{{ item.finish_date | date:"dd.M.y" }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">{{ item.count }} ({{ item.remaining_count }})</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <a href="#"
               class="btn btn_secondary no-wrap" (click)="edit(item.id, $event)">Редактировать</a>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <a href="#"
               class="btn btn_quaternary no-wrap" (click)="delete(item.id, $event)">Удалить</a>
        </div>
    </div>
</td>