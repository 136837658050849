import { AfterContentInit, Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IsAuthorizedFunction } from 'src/app/exports/functions/IsAuthorizedFunction';
import { UserService } from 'src/app/services/user.service';
import { BaoService } from 'src/app/services/bao.service';
import { EventService } from 'src/app/services/event.service';
import { IUserItem } from 'src/app/exports/interfaces/IUserItem';
import { IHttpResponse } from 'src/app/exports/interfaces/IHttpResponse';
import { STATIC_SALES, STATIC_SALES_PARAMS, STATIC_SALES_RESPONSE } from 'src/app/exports/constans';
import { Subscription } from 'rxjs';

@Component({
  selector: '[data-app-sales-page]',
  templateUrl: './sales-page.component.html',
  styleUrls: ['./sales-page.component.css']
})
export class SalesPageComponent implements OnInit, OnDestroy {

  private userSubscription: Subscription;
  user: IUserItem;
  init: boolean = false;
  event_id: number = 0;

  constructor(
    private bao$: BaoService,
    private user$: UserService,
    private router$: Router,
    private route$: ActivatedRoute,
    private event$: EventService
  ) {

    this.route$.params.subscribe(params => {
      if (typeof params == "object" && params.hasOwnProperty('event_id')) {
        this.event_id = params.event_id;
      }
    });

   }

  ngOnInit(): void {
    this.init = true;
    this.userSubscription = IsAuthorizedFunction(this.user$, this.router$).subscribe((user: any) => {
      if (user && user.hasOwnProperty('role') && user.role == 'viewer') {
        this.router$.navigateByUrl("account");
      }
      if (user) {
        this.user = user;
        if (this.init) {
          let params: any = {};

          if (user.id) {
            params = { orginiser: user.id };
          }

          if (this.event_id) {
            params.event = this.event_id;
          }

          this.bao$.getOrders(params).subscribe(response => {
            STATIC_SALES_RESPONSE.next(response);
            STATIC_SALES_PARAMS.next(params);
            if (response.results && Array.isArray(response.results))
              STATIC_SALES.next(response.results);
            });

          this.init = false;
        }
      }
    });
    IsAuthorizedFunction(this.user$, this.router$);
  }


  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

}