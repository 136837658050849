import { Component, OnInit } from '@angular/core';
import { IUserCheckerItem } from 'src/app/exports/interfaces/ICheckerItem';
import { IHttpResponse } from 'src/app/exports/interfaces/IHttpResponse';
import { IEventItem } from 'src/app/exports/interfaces/IEventItem';
import { IExportEvent } from 'src/app/exports/interfaces/IExportEvent';
import { ModalService } from 'src/app/services/modal.service';
import { UserService } from 'src/app/services/user.service';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: '[data-app-wrap-service-page]',
  templateUrl: './wrap-service-page.component.html',
  styleUrls: ['./wrap-service-page.component.css']
})
export class WrapServicePageComponent implements OnInit {

  items: Array<IExportEvent> = []
  isLoading: boolean = true;

  constructor(
    private event$: EventService,
    private modal$: ModalService,
    private user$: UserService,
  ) { 
    this.user$.user.subscribe(user => {
      if (typeof user == "object" && user.hasOwnProperty('id')) {
        this.event$.getExportEvents().subscribe(
          (response: IHttpResponse) => {
          if (Array.isArray(response.results)) {
            console.log(response.results)
            this.items = response.results;
            this.isLoading = false;
          } else {
            console.error('Error fetching export events');
            this.isLoading = false;
          }
        },
        (error) => {
          // Handle the error here
          console.error('Error fetching export events:', error);
          this.isLoading = false;
        }
      );
      }
    });
  }

  ngOnInit(): void {
  }



  openModal() {
    this.modal$.open('account-token-tiny')
  }
}
