<div class="formular">
    <div class="row">
        <div class="col col_12">
            <div class="form__group group">
                <div class="group__main">
                    <div class="form-item">
                        <div class="form-item__main">
                            <a routerLink="/account/design/banner/add" class="btn">Создать</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="event" *ngIf="items.length > 0">
    <div data-app-banner-page-item
        *ngFor="let item of items"
        [item]="item"
    ></div>
</div>