<!-- modal header -->
<div class="modal__header">
    <div class="modal__title title">
        <span class="title__text h3">Добавление токенов</span>
    </div>
    <div class="modal__action action">
        <svg class="action__icon" (click)="close($event)">
            <use xlink:href="/assets/img/sprite.svg#close"></use>
        </svg>
    </div>
</div>
<!-- modal content -->
<div class="modal__main">
    <div class="formular">
        <form [formGroup]="form">
            <fieldset>
                <div class="form__group group">
                    <div class="group__main">
                        <div class="form-item">
                            <div class="form-item__header">
                                <label class="form-item__title"
                                       for="check__surname">Yandex login</label>
                            </div>
                            <div class="form-item__main">
                                <div class="form-item__field">
                                    <div data-app-input
                                        [defaultValue]="defaultValue"
                                        [submitSubject]="formSubmit"
                                        setClass="form-item__input"
                                        [inputErrors]="errors"
                                        [c]="form.controls.yandex_login"
                                        placeholder="Введите данные"
                                        formControlName="yandex_login"
                                    >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item__header">
                                <label class="form-item__title"
                                       for="check__surname">Yandex password</label>
                            </div>
                            <div class="form-item__main">
                                <div class="form-item__field">
                                    <div data-app-input
                                        [defaultValue]="defaultValue"
                                        [submitSubject]="formSubmit"
                                        setClass="form-item__input"
                                        [inputErrors]="errors"
                                        [c]="form.controls.yandex_password"
                                        placeholder="Введите данные"
                                        formControlName="yandex_password"
                                    >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item__header">
                                <label class="form-item__title"
                                       for="check__surname">City id</label>
                            </div>
                            <div class="form-item__main">
                                <div class="form-item__field">
                                    <div data-app-input
                                        [defaultValue]="defaultValue"
                                        [submitSubject]="formSubmit"
                                        setClass="form-item__input"
                                        [inputErrors]="errors"
                                        [c]="form.controls.city_id"
                                        placeholder="Введите данные"
                                        formControlName="city_id"
                                    >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item__header">
                                <label class="form-item__title"
                                       for="check__name">Ticketclouds token</label>
                            </div>
                            <div class="form-item__main">
                                <div class="form-item__field">
                                    <div data-app-input
                                        [defaultValue]="defaultValue"
                                        [submitSubject]="formSubmit"
                                        setClass="form-item__input"
                                        [inputErrors]="errors"
                                        [c]="form.controls.ticketcloud_token"
                                        placeholder="Введите данные"
                                        formControlName="ticketcloud_token"
                                    >
                                    </div>
                                </div>
                            </div>
                        </div><!-- //form-item -->
                        <div class="form-item">
                            <div class="form-item__header">
                                <label class="form-item__title"
                                       for="check__parent">Qtickets token</label>
                            </div>
                            <div class="form-item__main">
                                <div class="form-item__field">
                                    <div data-app-input
                                        [defaultValue]="defaultValue"
                                        [submitSubject]="formSubmit"
                                        setClass="form-item__input"
                                        [inputErrors]="errors"
                                        [c]="form.controls.qticket_token"
                                        placeholder="Введите данные"
                                        formControlName="qticket_token"
                                    >
                                    </div>
                                </div>
                            </div>
                        </div><!-- //form-item -->
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</div>
<div class="modal__footer">
    <div class="row">
        <div class="col col_6">
            <a href="#" class="btn btn_secondary modal__action" (click)="close($event)">Отмена</a>
        </div>
        <div class="col col_6">
            <button type="button" class="btn" (click)="save()">Создать</button>
        </div>
    </div>
</div>