<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">№ {{ item.id }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item">{{ getUserName() }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="badge"
                [ngClass]="{
                    badge_300: item.status.id == 1,
                    badge_400: item.status.id == 2,
                    badge_500: item.status.id == 3 || item.status.id == 4
                }"
            >{{ item.status.name }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">{{ item.date | date: "dd.MM.Y, HH:mm" }}</span>
        </div>
    </div>
</td>
