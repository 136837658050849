<div data-app-header class="account-menu"></div>
<div class="layout layout_account">
    <main class="layout__main">
        <div data-app-sidebar class="layout__sidebar sidebar"></div>
        <!-- partial -->
        <div class="layout__content">
            <!-- СЕКЦИЯ НАСТРОЕК ЛК -->
            <div class="layout__section section">
                <div class="section__header">
                    <div class="row">
                        <div class="col col_8 col_tab-12">
                            <div class="wysiwyg">
                                <h1>Настройки профиля</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-app-wrap-account-settings></div>
            </div>
        </div>
    </main><!-- //middle -->
</div>