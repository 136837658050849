
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">{{ item.price }} р.</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__color" [ngStyle]="{backgroundColor: item.color}">
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">{{ (item.raising > 0) ? item.raising : 0 }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap" *ngIf="item.date_to">{{ item.date_to | date: "dd.M.Y" }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <a href="#"
               class="btn btn_secondary no-wrap" (click)="edit(item.id, $event)">Редактировать</a>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <a href="#"
               class="btn btn_quaternary no-wrap" (click)="delete(item.id, $event)">Удалить</a>
        </div>
    </div>
</td>
