import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VidgetTabComponent } from './vidget-tab.component';
import { ColorListModule } from 'src/app/components/modal/children/color-list/color-list.module';
import { FormsModule } from '@angular/forms';
import { NgForm } from '@angular/forms';



@NgModule({
  declarations: [VidgetTabComponent],
  imports: [
    CommonModule,
    ColorListModule,
    FormsModule
  ],
  exports: [VidgetTabComponent]
})
export class VidgetTabModule { }
