import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function atLeastOneRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const fixCount = control.get('fix_count')?.value;
    const dynamicCount = control.get('dynamic_count')?.value;

    if (fixCount === 0 && dynamicCount === 0) {
      return { atLeastOneRequired: true };
    }

    return null;
  };
}