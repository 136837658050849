import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageModule } from '../../../../../../../components/image/image.module';
import { MainEventItemComponent } from './main-event-item.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [MainEventItemComponent],
  imports: [
    CommonModule,
    ImageModule,
    RouterModule
  ],
  exports: [MainEventItemComponent]
})
export class MainEventItemModule { }
