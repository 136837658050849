<div class="layout__section section">
    <div class="section__header">
        <div class="wysiwyg">
            <h1>Вывод средств</h1>
        </div>
    </div>
    <div class="section__main" *ngIf="items.length > 0">
        <div class="account">
            <div class="account__withdrawals withdrawals">
                <div class="table">
                    <table class="table_custom table_withdrawals">
                        <thead>
                        <tr>
                            <th class="no-wrap">Название мероприятия</th>
                            <th class="no-wrap">Статус</th>
                            <th class="no-wrap">Продано билетов</th>
                            <th class="no-wrap">Сумма</th>
                            <th class="no-wrap">Комиссия</th>
                            <th class="no-wrap">Сервисный сбор</th>
                            <th class="no-wrap">Действия</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr data-app-withdrawal-page-item
                                *ngFor="let item of items"
                                [item]="item"
                                [checkouts]="getCheckouts(item.id)"
                            ></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
