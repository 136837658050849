import { Component, OnDestroy, OnInit } from '@angular/core';
import { IUserItem } from 'src/app/exports/interfaces/IUserItem';
import { AccountService } from 'src/app/services/account.service';
import { BaoService } from 'src/app/services/bao.service';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';
import { IHttpResponse } from 'src/app/exports/interfaces/IHttpResponse';

@Component({
  selector: '[data-app-menu-orginiser]',
  templateUrl: './menu-orginiser.component.html',
  styleUrls: ['./menu-orginiser.component.css']
})
export class MenuOrginiserComponent implements OnInit, OnDestroy {
  hasNewReturns: boolean = false;
  user: IUserItem;
  private userSubscription: Subscription;
  newReturns: number;

  constructor(
    private account$: AccountService,
    private bao$: BaoService,
    private user$: UserService
  ) {
    this.bao$.checkNewCheckoutReturns().subscribe((response: IHttpResponse) => {
      if (response.results) {
        this.newReturns = response.results;
      }
    });
    // this.bao$.getCheckoutReturns({ check: 'true' });
  }

  ngOnInit(): void {
    // this.bao$.newReturnNotification.subscribe(hasNewReturns => {
    //   this.hasNewReturns = hasNewReturns;
    // });
  }

  logout(event: any) {
    event.preventDefault();
    this.account$.logout();
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

}
