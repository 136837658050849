<div class="row">
    <div class="col col_3 col_hd-desktop-12">
        <div class="account__filter filter">
            <div class="filter__header">
                <div class="form-item form-item_before">
                    <div class="form-item__main">
                      <div class="form-item__field">
                        <input class="form-item__input" type="text"
                        placeholder="Поиск по названию"
                        [(ngModel)]="searchQuery"
                        (keyup)="updateSearchParams()"
                    >
                      </div>
                      <div class="form-item__media media">
                        <svg class="media__ico">
                          <use xlink:href="/assets/img/sprite.svg#search"></use>
                        </svg>
                      </div>
                    </div>
                  </div><!-- //form-item -->

                  <div>
                    <label>
                      <input type="radio" name="orderOption" value="newest" (change)="getOrder('newest')"> Сначала новые
                    </label>
                    <label>
                      <input type="radio" name="orderOption" value="oldest" (change)="getOrder('oldest')"> Сначала старые
                    </label>
                  </div>
            </div>
            <div class="main__loading" *ngIf="isLoading">
                <img src="/assets/img/content/loader/loader-spin.svg" alt="">
              </div>
            <div data-app-accordion-filter-block
                [items]="items"
            ></div>
        </div>
    </div>
    <div class="col col_9 col_hd-desktop-12">
        <div data-app-tags-filter-block
        ></div>
        <div class="events" *ngIf="items.length > 0">
            <div class="events__list">
                <div class="row">
                    <div class="col col_4 col_full-hd-desktop-6 col_hd-desktop-4 col_big-desktop-6 col_mob-12" *ngFor="let item of items">
                        <div data-app-main-event-item [user]="user" [item]="item"></div>
                    </div>
                </div>
            </div>
        </div>

        <div 
            *ngIf="response"
            data-app-pagination 
            dataType="events"
            [parameters]="params"
            [response]="response"
            [elements]="elements"
        ></div>
    </div>
</div>
