<div class="row">
    <div class="col col_10 col_tab-12">
        <div class="table">
            <table class="table_custom table_event-distributors">
                <thead>
                <tr>
                    <th class="no-wrap">Название</th>
                    <th class="no-wrap">Ссылка</th>
                    <th class="no-wrap">Комиссия</th>
                    <th class="no-wrap">Сервисный сбор</th>
                    <th class="no-wrap">Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <div class="table__group group">
                            <div class="group__media media">
                                <img class="media__img"
                                     data-src="assets/img/content/event/event__img01.jpg"
                                     src="assets/img/preview.jpg"
                                     alt="image description">
                            </div>
                            <div class="group__main">
                                <span class="group__item">Яндекс Афиша</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="table__group group">
                            <div class="group__main">
                                <a class="group__item" href="#">https://afisha.yandex.ru/</a>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="table__group group">
                            <div class="group__main">
                                <span class="group__item no-wrap">154 р.</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="table__group group">
                            <div class="group__main">
                                <span class="group__item no-wrap">50 р.</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="table__group group">
                            <div class="group__main">
                                <a href="#"
                                   class="btn btn_secondary no-wrap">Сотрудничать</a>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="table__group group">
                            <div class="group__media media">
                                <img class="media__img"
                                     data-src="assets/img/content/event/event__img01.jpg"
                                     src="assets/img/preview.jpg"
                                     alt="image description">
                            </div>
                            <div class="group__main">
                                <span class="group__item">Яндекс Афиша</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="table__group group">
                            <div class="group__main">
                                <a class="group__item" href="#">https://afisha.yandex.ru/</a>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="table__group group">
                            <div class="group__main">
                                <span class="group__item no-wrap">154 р.</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="table__group group">
                            <div class="group__main">
                                <span class="group__item no-wrap">50 р.</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="table__group group">
                            <div class="group__main">
                                <a href="#"
                                   class="btn btn_secondary no-wrap">Сотрудничать</a>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="table__group group">
                            <div class="group__media media">
                                <img class="media__img"
                                     data-src="assets/img/content/event/event__img01.jpg"
                                     src="assets/img/preview.jpg"
                                     alt="image description">
                            </div>
                            <div class="group__main">
                                <span class="group__item">Яндекс Афиша</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="table__group group">
                            <div class="group__main">
                                <a class="group__item" href="#">https://afisha.yandex.ru/</a>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="table__group group">
                            <div class="group__main">
                                <span class="group__item no-wrap">154 р.</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="table__group group">
                            <div class="group__main">
                                <span class="group__item no-wrap">50 р.</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="table__group group">
                            <div class="group__main">
                                <a href="#"
                                   class="btn btn_secondary no-wrap">Сотрудничать</a>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="formular">
            <form>
                <fieldset>
                    <div class="form__group group">
                        <div class="group__header">
                            <div class="wysiwyg">
                                <h3>Где рекламировать мероприятие?</h3>
                            </div>
                        </div>
                        <div class="group__main">
                            <div class="row">
                                <div class="col col_12">
                                    <div class="form-item">
                                        <div class="form-item__main">
                                            <div class="custom-check">
                                                <label class="custom-check__label">
                                                    <input class="custom-check__input"
                                                           type="radio"
                                                           name="radio"
                                                           required>
                                                    <svg class="custom-check__ico custom-check__ico_before">
                                                        <use xlink:href="assets/img/sprite.svg#radioBefore"></use>
                                                    </svg>
                                                    <svg class="custom-check__ico custom-check__ico_after">
                                                        <use xlink:href="assets/img/sprite.svg#radioAfter"></use>
                                                    </svg>
                                                    <span class="custom-check__text">Виджет VK</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div><!-- //form-item -->
                                </div>
                                <div class="col col_12">
                                    <div class="form-item">
                                        <div class="form-item__main">
                                            <div class="custom-check">
                                                <label class="custom-check__label">
                                                    <input class="custom-check__input"
                                                           type="radio"
                                                           name="radio"
                                                           required>
                                                    <svg class="custom-check__ico custom-check__ico_before">
                                                        <use xlink:href="assets/img/sprite.svg#radioBefore"></use>
                                                    </svg>
                                                    <svg class="custom-check__ico custom-check__ico_after">
                                                        <use xlink:href="assets/img/sprite.svg#radioAfter"></use>
                                                    </svg>
                                                    <span class="custom-check__text">Витрина сайта</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div><!-- //form-item -->
                                </div>
                                <div class="col col_12">
                                    <div class="form-item">
                                        <div class="form-item__main">
                                            <div class="custom-check">
                                                <label class="custom-check__label">
                                                    <input class="custom-check__input"
                                                           type="radio"
                                                           name="radio"
                                                           required>
                                                    <svg class="custom-check__ico custom-check__ico_before">
                                                        <use xlink:href="assets/img/sprite.svg#radioBefore"></use>
                                                    </svg>
                                                    <svg class="custom-check__ico custom-check__ico_after">
                                                        <use xlink:href="assets/img/sprite.svg#radioAfter"></use>
                                                    </svg>
                                                    <span class="custom-check__text">Распространители</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div><!-- //form-item -->
                                </div>
                            </div>

                        </div>

                        <div class="group__footer">
                            <div class="row">
                                <div class="col col_6 col_tab-12">
                                    <div class="form-item">
                                        <div class="form-item__main">
                                            <button type="submit"
                                                    class="btn btn_secondary">
                                                Отмена
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col_6 col_tab-12">
                                    <div class="form-item">
                                        <div class="form-item__main">
                                            <button type="submit"
                                                    class="btn">
                                                Сохранить
                                                мероприятие
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>
