<td (click)="showDetails(item)">
    <div class="table__group group" *ngFor="let groupedItem of groupedItems">
        <div class="group__media media">
            <div data-app-image class="media__img" [src]="groupedItem.items[0].product.afisha.image.small">
            </div>
        </div>
        <div class="group__main">
            {{ groupedItem.items[0].product.name }}
            <span class="group__item group__item_300">
                {{ getTypes(groupedItem.items[0]) }}
            </span>
        </div>
    </div>
</td>
<td (click)="showDetails(item)">
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item">{{item.created_at | date: "dd.MM.Y HH:mm" }}</span>
        </div>
    </div>
</td>
<td (click)="showDetails(item)">
    <div class="table__group group" *ngFor="let groupedItem of groupedItems">
        <div class="group__main">
            <p class="group__item group__item_300">{{ groupedItem.items[0].product.city.name }}</p>
            <p class="group__item group__item_300">{{ groupedItem.items[0].product.area.name }}</p>
        </div>
    </div>
</td>
<td (click)="showDetails(item)">
    <div class="table__group group" *ngFor="let groupedItem of groupedItems">
        <div class="group__main">
            <span *ngFor="let category of getCategoryQuantities(groupedItem.items)">
                {{ category.categoryName }}: {{ category.quantity }}<br>
            </span>
        </div>
    </div>
</td>
<td (click)="showDetails(item)">
    <div class="table__group group" *ngFor="let groupedItem of groupedItems">
        <div class="group__main" *ngIf="getTotalDiscount(groupedItem.items) > 0">
            <span class="group__item discount"><strong>{{ getTotalPrice(groupedItem.items) }} р.</strong></span>
            <span class="group__item">{{ getTotalDiscount(groupedItem.items) }} р.</span>
            <span class="group__item group__item_500">{{ item.status.name }}</span>
        </div>
        <div class="group__main" *ngIf="getTotalDiscount(groupedItem.items) == 0">
            <span class="group__item">{{ getTotalPrice(groupedItem.items) }} р.</span>
            <span class="group__item group__item_500">{{ item.status.name }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main" *ngIf="item.status.code == 'paid' &&  !ifCanceled(item)">
            <button class="btn btn_secondary" (click)="repeatBilets($event)">Отправить билеты</button>
            <br />
            <button class="btn btn_tertiary" *ngIf="item.payment.code != 'free' && ifCanceled(item)"
                (click)="refundOrder($event)">Возврат
                оплаты</button>
        </div>
    </div>
</td>