<div class="layout__section section">
    <div class="section__header">
        <div class="wysiwyg">
            <h1 *ngIf="id == 0">Создание спецпредложения</h1>
            <h1 *ngIf="id > 0">Обновление спецпредложения</h1>
        </div>
    </div>
    <div class="section__main">
        <div class="formular">
            <form [formGroup]="form" class="form">
                <fieldset>
                    <div class="row">
                        <div class="col col_10 col_tab-12">
                            <div class="form__group group">
                                <div class="group__main">
                                    <div class="row">
                                        <div class="col col_12">
                                            <div class="form-item">
                                                <div class="form-item__header">
                                                    <label class="form-item__title" for="special__name">Название</label>
                                                </div>
                                                <div class="form-item__main">
                                                    <div class="form-item__field">
                                                        <div data-app-input
                                                            [defaultValue]="defaultValue"
                                                            [submitSubject]="formSubmit"
                                                            setClass="form-item__input"
                                                            [inputErrors]="errors"
                                                            [c]="form.controls.name"
                                                            placeholder="Введите данные"
                                                            formControlName="name"
                                                        >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- //form-item -->
                                        </div>
                                        <div class="col col_6 col_mob-12">
                                            <div class="form-item">
                                                <div class="form-item__header">
                                                    <label class="form-item__title"
                                                           for="special__genres">Жанры</label>
                                                </div>
                                                <div class="form-item__main">
                                                    <div class="form-item__field">
                                                        <div data-app-select
                                                            [defaultValue]="defaultValue"
                                                            [submitSubject]="formSubmit"
                                                            setClass="form-item__input"
                                                            sugg_url="/api/event_genre"
                                                            [get_data]="{
                                                                name: 'name',
                                                                value: 'id'
                                                            }"
                                                            [inputErrors]="errors"
                                                            [multiple]="true"
                                                            [c]="form.controls.genres"
                                                            placeholder="Выберите жанр"
                                                            formControlName="genres"
                                                            (data)="getGenre($event)"
                                                        >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- //form-item -->
                                        </div>
                                        <div class="col col_6 col_mob-12">
                                            <div class="form-item">
                                                <div class="form-item__header">
                                                    <label class="form-item__title"
                                                           for="special__events">Мероприятия</label>
                                                </div>
                                                <div class="form-item__main">
                                                    <div class="form-item__field">
                                                        <div data-app-select
                                                            [defaultValue]="defaultValue"
                                                            [submitSubject]="formSubmit"
                                                            setClass="form-item__input"
                                                            sugg_url="/api/event/"
                                                            [get_data]="{
                                                                name: 'name',
                                                                value: 'id'
                                                            }"
                                                            [inputErrors]="errors"
                                                            [multiple]="true"
                                                            [c]="form.controls.events"
                                                            placeholder="Выберите мероприятие"
                                                            formControlName="events"
                                                            (data)="getEvent($event)"
                                                        >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- //form-item -->
                                        </div>
                                        <div class="col col_12">
                                            <div class="form-item">
                                                <div class="form-item__header">
                                                    <label class="form-item__title"
                                                           for="special__description">Описание</label>
                                                </div>
                                                <div class="form-item__main">
                                                    <div class="form-item__field">
                                                        <div data-app-input
                                                            setClass="form-item__input form-item__input_textarea"
                                                            type="textarea"
                                                            [defaultValue]="defaultValue"
                                                            [submitSubject]="formSubmit"
                                                            [inputErrors]="errors"
                                                            [c]="form.controls.description"
                                                            placeholder="Введите данные"
                                                            formControlName="description"
                                                        >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- //form-item -->
                                        </div>
                                    </div>
                                </div>
                                <div class="group__footer">
                                    <div class="row">
                                        <div class="col col_12">
                                            <!-- Отправка формы -->
                                            <div class="form-item">
                                                <div class="form-item__main">
                                                    <button type="button" (click)="save()" class="btn">
                                                        <span *ngIf="id == 0">Создать</span>
                                                        <span *ngIf="id > 0">Обновить</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>
