import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IEventPrice } from '../exports/interfaces/IEventPrice';
import { IEventAreaCategory } from '../exports/interfaces/IEventAreaCategory';
import { MessageService } from './message.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ScriptBuyService {

  $id: string;
  getting = new Subject;
  prices: number[] = [];
  count: number;

  constructor(
    private message$: MessageService
  ) { }

  setId(id: string) {
    this.$id = id;
  }

  clicking() {
    //$(document).unbind('click.Scheme')
    $(document).on('click', `#${this.$id} .operating:not(.busy)`, (el: any) => {
      const $this = $(el.target);
      const place_id = parseInt($this.attr('data-place-id'));
      const price_id = parseInt($this.attr('data-price-id'));
      if (place_id > 0 && price_id > 0)
        this.classing(place_id, price_id, $this);
    });
  }

  classing(place_id: number, price_id: number, $this: any) {
    if (!this.prices.includes(place_id)) {
      if (this.count >= 10) {
        this.message$.handle('нельзя заказать больше 10 билетов');
      } else {
        $this.addClass('pre-busy');
        this.prices.push(place_id);
        this.getting.next({ place_id, price_id, action: 'add' });
      }
    } else {
      $this.removeClass('pre-busy');
      this.prices.splice(this.prices.indexOf(place_id), 1);
      this.getting.next({ place_id, price_id, action: 'remove' });
    }
  }

  setPrice(data: IEventPrice[], scheme_id: number) {
    data.map((element: IEventPrice) => {
      element.place.map(place => {
        if (place.scheme.filter(i => i == scheme_id).length > 0) {
          const item = $(`#${this.$id} [data-place-id="${place.id}"]`);
          if (item.length > 0) {
            item.attr('data-price-id', element.id);
            const id = parseInt(item.attr('data-place-id'));
            if (!element.basket.includes(id)) {
              item.removeAttr('data-price-id');
              item.attr('data-price-id', element.id);
              if (!this.prices.includes(id)) {
                item.css('fill', element.color);
                item.removeClass('pre-busy');
                item.attr('height', 16);
                item.attr('width', 16);
                item.attr('rx', 8);
              } else {
                item.css('fill', '');
                item.addClass('pre-busy');
              }
            } else {
              item.addClass('busy');
            }
          }
        }
      });
    });
  }

  setCategory(data: IEventAreaCategory[], scheme_id: number) {
    data.map((element: IEventAreaCategory) => {
      element.place.map(place => {
        const item = $(`#${this.$id} [data-category="${place}"]`);
        if (item.length > 0) {
          const id = parseInt(item.attr('data-category'));
          item.removeAttr('data-category-id');
          item.attr('data-category-id', element.id);
          item.css('fill', element.color);
        }
      });
    });
    $(`#${this.$id} [data-category]:not([data-category-id])`).addClass('hidden');
  }

  setTooltips(prices: IEventPrice[], categories: IEventAreaCategory[]) {
    $(document).on('mouseenter', `#${this.$id} [data-price-id]`, (el: any) => {
      const $this = $(el.target);
      const place_id = parseInt($this.attr('data-place-id'));
      const price_id = parseInt($this.attr('data-price-id'));
      const price = prices.find(item => item.id === price_id);
      const place = price?.place.find(item => item.id === place_id);
      const tooltip = $('<div class="place__tooltip"></div>');

      if (price && place) {
        let priceDiv;
        if ($this.hasClass('busy')) {
          priceDiv = $('<div>').text('Место занято');
        } else { priceDiv = $('<div>').text(price?.price + ' р.'); }
        const placeDiv = $('<div>').text(place?.name + ' место ' + place?.row);
        tooltip.append(priceDiv, placeDiv);
      }

      tooltip.css({
        top: el.pageY - 60,
        left: el.pageX - 80,
      });
      $('.scene__main').append(tooltip);
    });
    $(document).on('mouseleave', `#${this.$id} [data-price-id]`, (el: any) => {
      $(document).find('.place__tooltip');
      const $tooltip = $(document).find('.place__tooltip');
      $tooltip.fadeOut(300, function () {
        $tooltip.remove();
      });
    });

    $(document).on('mouseenter', `#${this.$id} [data-category-id]`, (el: any) => {
      const $this = $(el.target);
      const category_id = parseInt($this.attr('data-category-id'));
      const category = categories.find(item => item.id === category_id);
      const tooltip = $('<div class="place__tooltip"></div>');
      if (category) {
        const priceDiv = $('<div>').text(category.price + ' р.');
        const placeDiv = $('<div>').text(category.name + ' ' + (category.count - category.basket) + ' билетов');
        tooltip.append(priceDiv, placeDiv);
      }

      tooltip.css({
        top: el.pageY - 60,
        left: el.pageX - 80,
      });
      $('.scene__main').append(tooltip);
    });
    $(document).on('mouseleave', `#${this.$id} [data-category-id]`, (el: any) => {
      $(document).find('.place__tooltip');
      const $tooltip = $(document).find('.place__tooltip');
      $tooltip.fadeOut(300, function () {
        $tooltip.remove();
      });
    });
  }

  setGrouping(prices: IEventPrice[], categories: IEventAreaCategory[]) {
    $(document).on('click', `#${this.$id} .buy_categories__main`, (el: any) => {
      console.log(".buy_categories__main");
    });
    // $(document).on('click', `#${this.$id} .categories__label`, (el: any) => {
    //   console.log('click');
    //   const $this = $(el.target);
    //   const price_id = parseInt($this.attr('price-label-mark'));
    //   console.log(price_id);
    // });
    // $(document).on('click', `#${this.$id} .categories__label`, (el: any) => {
    //   console.log('click');
    //   const $this = $(el.target);
    //   const cat_id = parseInt($this.attr('cat-label-mark'));
    //   console.log(cat_id);
    // });
  }

  delPlace(place: number) {
    this.prices = this.prices.filter(item => item != place);
  }
}
