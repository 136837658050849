import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrapServicePageItemComponent } from './wrap-service-page-item.component';
import { RouterModule } from '@angular/router';
import { ImageModule } from 'src/app/components/image/image.module';



@NgModule({
  declarations: [WrapServicePageItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    ImageModule
  ],
  exports: [WrapServicePageItemComponent]
})
export class WrapServicePageItemModule { }
