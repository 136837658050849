<div class="layout__section section">
    <div class="section__header">
        <div class="wysiwyg">
            <h1>Промоакции</h1>
        </div>
    </div>
    <div class="section__main">
        <div class="account">
            <div class="account__promotions promotions">
                <div data-app-promocode-filter-block></div>

                <div class="table" *ngIf="items.length > 0">
                    <table class="table_custom table_promotions-admin">
                        <thead>
                        <tr>
                            <th class="no-wrap">Название</th>
                            <th class="no-wrap">Должность</th>
                            <th class="no-wrap">Название мероприятия</th>
                            <th class="no-wrap">Начало</th>
                            <th class="no-wrap">Окончание</th>
                            <th class="no-wrap">Использования</th>
                            <th class="no-wrap">Скидка</th>
                            <th class="no-wrap">Действия</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr data-app-admin-promocode-item
                                *ngFor="let item of items"
                                [item]="item"
                            ></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
