<div data-app-header class="account-menu"></div>
<div class="layout layout_account">
    <main class="layout__main">
        <div data-app-sidebar class="layout__sidebar sidebar"></div>
        <!-- partial -->
        <!-- СЕКЦИЯ ЧЕКИН -->
        <div class="layout__content">
            <!-- <div data-app-wrap-promocode-admin-page *ngIf="user && user.role == 'admin'"></div> -->
            <div data-app-wrap-promocode-page></div>
        </div>
    </main>
</div>
