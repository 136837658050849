import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[data-app-wrap-promotion-page]',
  templateUrl: './wrap-promotion-page.component.html',
  styleUrls: ['./wrap-promotion-page.component.css']
})
export class WrapPromotionPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
