import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[data-app-distribute-tab]',
  templateUrl: './distribute-tab.component.html',
  styleUrls: ['./distribute-tab.component.css']
})
export class DistributeTabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
