<div class="row">
    <div class="col col_3 col_hd-desktop-12">
        <div class="account__filter filter">
            <div class="filter__header">
                <div class="form-item">
                    <div class="form-item__main">
                        <div class="form-item__field">
                            <div data-app-select
                                [values]="ordering"
                                placeholder="Сортировать"
                                (data)="getOrder($event)"
                            ></div>
                        </div>
                    </div>
                </div><!-- //form-item -->
            </div>
            <div class="main__loading" *ngIf="isLoading">
                <img src="/assets/img/content/loader/loader-spin.svg" alt="">
              </div>
            <div data-app-accordion-filter-block
                [items]="items"
            ></div>
        </div>
    </div>
    <div class="col col_9 col_hd-desktop-12">
        <div data-app-accordion-item
        class="accordion__item" 
        title="Продажи"
        *ngIf="maxTotalSells > 0"
    >
    <div class="accordion__title">
        <span class="title__text">Продажи</span>
    </div>
        <div data-app-according-range-block
            (data)="updateTotalSellsFilter($event)"
            [min_val]="minTotalSells"
            [max_val]="maxTotalSells"
            style="margin-bottom: 2rem;"
        ></div>
    </div>
        <div data-app-tags-filter-block
        ></div>
        <div class="events" *ngIf="items.length > 0">
            <div class="events__list">
                <div class="row">
                    <div class="col col_4 col_full-hd-desktop-6 col_hd-desktop-4 col_big-desktop-6 col_mob-12" *ngFor="let item of items">
                        <div data-app-statistic-event-item [user]="user" [item]="item"></div>
                    </div>
                </div>
            </div>
        </div>

        <div 
            *ngIf="response"
            data-app-pagination 
            dataType="events"
            [parameters]="params"
            [response]="response"
            [elements]="elements"
        ></div>
    </div>
</div>
