import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function atLeastOneTokenValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const yandexToken = control.get('yandex_token')?.value;
    const ticketcloudToken = control.get('ticketcloud_token')?.value;
    const qticketToken = control.get('qticket_token')?.value;

    if (yandexToken === 0 && ticketcloudToken === 0 && qticketToken === 0) {
      return { atLeastOneTokenValidator: true };
    }

    return null;
  };
}