<div class="layout">
    <div data-app-header></div>
    <main class="layout__main"> 
        <div data-app-breadcrumbs></div>
        <!-- СЕКЦИЯ КОРЗИНЫ -->
        <div class="layout__section section">
            <div class="section__header">
                <div class="container">
                    <div class="wysiwyg">
                        <h1>Корзина</h1>
                    </div>
                </div>
            </div>
            <div data-app-basket-wrap></div>
        </div>
    </main>
    <div data-app-footer></div>
</div>