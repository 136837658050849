import { Component, OnInit } from '@angular/core';
import { IHttpResponse } from 'src/app/exports/interfaces/IHttpResponse';
import { IPageItem } from 'src/app/exports/interfaces/IPageItem';
import { PageService } from 'src/app/services/page.service';
import { StaticJsonDataService } from 'src/app/services/staticjsondata.service';

declare var $: any;

@Component({
  selector: 'app-contact-block',
  templateUrl: './contact-block.component.html',
  styleUrls: ['./contact-block.component.css']
})
export class ContactBlockComponent implements OnInit {

  item: IPageItem;
  contentValue: string;
  isFooterActive = false;

  constructor(
    private page$: PageService,
    private staticJsonDataService: StaticJsonDataService
  ) {
  }


  ngOnInit(): void {
    const jsonDataUrl: string = `${StaticJsonDataService.JSON_DATA_DIR_URL}/contact-info.json`;
    this.staticJsonDataService.fetchJsonData(jsonDataUrl).then((jsonData) => {
      this.contentValue = jsonData[0].content;
    });
    this.page$.getPage('footer').subscribe((response: IHttpResponse) => {
      if (response.results)
        this.item = response.results;
    });
  }

}
