<div data-app-header class="account-menu"></div>
<div class="layout layout_account">
    <main class="layout__main">
        <div data-app-sidebar class="layout__sidebar sidebar"></div>
        <!-- partial -->
        <div class="layout__content">
            <!-- СЕКЦИЯ РАССЫЛКИ -->
            <div class="layout__section section">
                <div data-app-wrap-promotion-page></div>
            </div>
        </div>
    </main>
</div>
