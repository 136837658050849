<div class="basket__group group" *ngIf="product">
    <div class="group__main">
        <div class="group__media media">
            <a class="media__link" [routerLink]="['/event', product.id]">
                <div 
                    data-app-image 
                    class="media__img" 
                    [src]="product.afisha.image.small"
                ></div>
            </a>
        </div>
        <div class="group__content">
            <div style="display: flex; align-items:baseline;">
            <div class="group__title title" style="margin-right: 3rem;">
                <a class="title__text h3" [routerLink]="['/event', product.id]">
                    {{ product.name }}
                </a>
            </div>
            <div class="group__type type">
                <span class="type__text">{{ getTypes() }}</span>
            </div>
            </div>
            <div class="group__second">
                <table class="table_basket">
                    <thead>
                        <th style="width: 20%;"></th>
                        <th style="width: 30%;"></th>
                        <th style="width: 20%;"></th>
                        <th style="width: 20%;"></th>
                        <th style="width: 10%;"></th>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of group.items">
                        <td>
                            <div class="data__item">
                                <span class="data__text">{{ item.date.start_date | date:"dd.MM.Y" }}, {{ item.date.start_date | date:"HH:mm" }}</span>
                            </div>
                        </td>
                        <td [innerHTML]="getName(item)"></td>
                        <td [innerHTML]="getPrice(item)"></td>
                        <td>
                            <div class="form-item">
                                <div class="form-item__main">
                                    <div class="form-item__basket">
                                        <div data-app-counter
                                            (data)="setCount(item, $event)"
                                            [value]="item.quantity"
                                            *ngIf="item.category"
                                            [max_length]="item.available"
                                            [min_length]="1"
                                        ></div>
                                    </div>
                                </div>
                            </div><!-- //form-item -->
                        </td>
                        <td>
                            <div class="group__action action" (click)="removeProduct(item)">
                            <svg class="action__ico" style="height:20px">
                                <use xlink:href="/assets/img/sprite.svg#basket"></use>
                            </svg>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <tfoot>
                    <tr>
                        <td style="width: 70%;"></td>
                        <td style="width: 30%;"><h3>Итого: {{totalPrice()}} р.  Билеты: {{ getTotalQuantity() }}</h3></td>
                    </tr>
                </tfoot>
            </div>
        </div>
    </div>
</div>
