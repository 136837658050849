import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ErrorHandlerClass } from 'src/app/exports/classes/ErrorHandlerClass';
import { BaoService } from 'src/app/services/bao.service';
import { PHONE_REG_EXP } from 'src/app/exports/constans';
import { IHttpResponse } from 'src/app/exports/interfaces/IHttpResponse';
import { IModalTypeParams } from 'src/app/exports/interfaces/IModalTypeParams';
import { ModalType } from 'src/app/exports/types/ModalType';
import { MessageService } from 'src/app/services/message.service';
import { ModalService } from 'src/app/services/modal.service';
import { UserService } from 'src/app/services/user.service';
import { atLeastOneTokenValidator } from "../../../validations/tokenvalidation";

@Component({
  selector: 'div[data-app-modal-token]',
  templateUrl: './modal-token.component.html',
  styleUrls: ['./modal-token.component.css']
})
export class ModalTokenComponent implements OnInit {

  modal: ModalType = 'account-token-tiny'

  @Output() data = new EventEmitter<ModalType>()
  @Input() params: IModalTypeParams

  formSubmit: Subject<any> = new Subject()
  defaultValue = new Subject()
  errors: Subject<any> = new Subject()
  color: string = ""
  id: number = 0

  form = new FormGroup({
    yandex_login: new FormControl<string>(''),
    yandex_password: new FormControl<string>(''),
    city_id: new FormControl<string>(''),
    ticketcloud_token: new FormControl<string>(''),
    qticket_token: new FormControl<string>(''),
  }, { validators: atLeastOneTokenValidator() })

  constructor(
    private bao$: BaoService,
    private modal$: ModalService,
    private user$: UserService,
    private message$: MessageService
  ) { }

  ngOnInit(): void {
    if(this.params.type == "account-token-tiny") {
      if(
        typeof this.params.params && 
        this.params.params.hasOwnProperty('edit') &&
        this.params.params.edit
      )
      {
        // this.user$.getChecker(this.params.params.id)
        //   .subscribe((response: IHttpResponse) => {
        //     if(response.results) {
        //       if(typeof response.results == "object") {
        //         for(let key in response.results) {
        //           const control = this.form.get(key)
        //           if(control) {
        //             control.setValue(response.results[key])
        //           }

        //           if(key == 'id')
        //             this.id = response.results[key]
        //         }
        //       }
        //       setTimeout(() => {
        //         this.defaultValue.next(response.results)
        //       }, 300)
        //     }
        // })
      }
    }
  }

  save() {
    this.formSubmit.next({})
    this.errors.next({})
    console.log('this.form', this.form.value)
    if(!ErrorHandlerClass.AnyErrors(this.form)) {

      this.user$.getErrors().subscribe(errors => {
        if(errors.hasOwnProperty('error'))
          this.errors.next(errors.error)
      })

      this.bao$.addToken(this.form.value).subscribe(response => {
        console.log('adding token')
        this.message$.handle('Токен успешно добавлен', 'success')
        this.data.emit(this.modal)
      })

      // if(
      //   typeof this.params.params && 
      //   this.params.params.hasOwnProperty('edit') &&
      //   this.params.params.edit
      // ) {
      //   this.bao$.updateChecker(this.id, this.form.value).subscribe(response => {
      //     this.message$.handle('Проверяющий успешно обновлен', 'success')
      //     this.data.emit(this.modal)
      //     this.user$.getCheckers()
      //   })
      // } else {
      //   this.bao$.addChecker(this.form.value).subscribe(response => {
      //     this.message$.handle('Проверяющий успешно добавлен', 'success')
      //     this.data.emit(this.modal)
      //     this.user$.getCheckers()
      //   })
      // }
    }
  }

  close(event: any) {
    event.preventDefault()
    this.data.emit(this.modal)
  }

}
