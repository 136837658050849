import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { ErrorHandlerClass } from 'src/app/exports/classes/ErrorHandlerClass';
import { IBasketItem } from 'src/app/exports/interfaces/IBasketItem';
import { ICheckoutItem } from 'src/app/exports/interfaces/ICheckoutItem';
import { ICheckoutReturn } from 'src/app/exports/interfaces/ICheckoutReturn';
import { ICheckoutReturnReason } from 'src/app/exports/interfaces/ICheckoutReturnReason';
import { IHttpResponse } from 'src/app/exports/interfaces/IHttpResponse';
import { ISelectValue } from 'src/app/exports/interfaces/ISelectValue';
import { IUserItem } from 'src/app/exports/interfaces/IUserItem';
import { BaoService } from 'src/app/services/bao.service';
import { MessageService } from 'src/app/services/message.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: '[data-app-wrap-return-page]',
  templateUrl: './wrap-return-page.component.html',
  styleUrls: ['./wrap-return-page.component.css']
})
export class WrapReturnPageComponent implements OnInit {

  formSubmit: Subject<any> = new Subject();
  user: IUserItem;
  defaultValue = new Subject();
  errors: Subject<any> = new Subject();
  checkouts: ISelectValue[] = [];
  reasons: ISelectValue[] = [];
  orders: ICheckoutItem[] = [];
  baskets: number[] = [];
  selectedBaskets: number[] = [];
  id: number = 0;
  item: ICheckoutReturn;
  review: boolean = false;

  form = new FormGroup({
    name: new FormControl<string>('', [
      Validators.required
    ]),
    checkout: new FormControl<number>(0, [
      Validators.required
    ]),
    reason: new FormControl<number>(0, [
      Validators.required
    ]),
    description: new FormControl<string>('', [
      Validators.required
    ]),
    basket: new FormControl<number[]>([]),
    seria_passport: new FormControl<string>(''),
    number_passport: new FormControl<string>(''),
    date_passport: new FormControl<string>(''),
    given_passport: new FormControl<string>(''),
    inn_bank: new FormControl<string>(''),
    bik_bank: new FormControl<string>(''),
    receipt_bank: new FormControl<string>(''),
    korr_bank: new FormControl<string>(''),
    total_amount: new FormControl<number>(0, [
      Validators.required
    ]),
  });

  constructor(
    private bao$: BaoService,
    private message$: MessageService,
    private activate$: ActivatedRoute,
    private user$: UserService
  ) {
    this.activate$.params.subscribe(params => {
      if (typeof params == "object" && params.hasOwnProperty('id') && params.id > 0) {
        this.id = params.id;
        this.user$.user.subscribe((user: any) => {
          if (user && typeof user == 'object' && 'id' in user) {
            this.user = user;
            if (this.user.role == 'manager') {
              this.review = true;
            }
          }
        });
        this.bao$.getCheckoutReturn(this.id).subscribe((response: IHttpResponse) => {
          if (response.results) {
            this.item = response.results;
            this.bao$.markCheckoutReturnAsViewed(this.id).subscribe(() => {
              // Optionally, update the UI or perform other actions after marking as viewed
            });
            this.baskets = this.item.checkout.products.filter(item => item.product.id == this.item.baskets[0].product.id).map((basket: IBasketItem) => basket.id);
            this.selectedBaskets = this.item.baskets.map((basket: IBasketItem) => basket.id);
            for (let item in response.results) {
              const control = this.form.get(item);
              if (control) {
                control.setValue(response.results[item]);
              }
            }

            setTimeout(() => {
              this.defaultValue.next(response.results);
            }, 1000);
          }
        });
      }
    });
  }

  ngOnInit(): void {
    this.bao$.getOrders({ status: 2 }).subscribe((response: IHttpResponse) => {
      if (Array.isArray(response.results)) {
        this.orders = response.results;
        const arr: any[] = [];
        response.results.forEach((item: ICheckoutItem) => {
          const basketIdsArray = item.products.map(basket => basket.id);
          const basketIdsString = basketIdsArray.join(', ');
          arr.push({
            name: `Зак. ${item.id} Бил. №${basketIdsString}`,
            value: item.id
          });
        });
        // if(Array.isArray(respoense.results)) {
        //   const arr: any = []
        //   respoense.results.map((item: ICheckoutItem) => {
        //     arr.push({
        //       name: `Заказ №${item.id}`,
        //       value: item.id
        //     })
        //   })
        this.checkouts = arr;
      }
    });
    this.bao$.getCRReasons().subscribe((response: IHttpResponse) => {
      if (Array.isArray(response.results)) {
        const arr: any = [];
        response.results.map((item: ICheckoutReturnReason) => {
          arr.push({
            name: item.name,
            value: item.id
          });
        });
        this.reasons = arr;
      }
    });
  }

  getBaskets(checkout: any) {
    const order = this.orders.find(item => item.id == Number(checkout));
    if (order) {
      this.baskets = order.products.map((basket: IBasketItem) => basket.id);
      this.selectedBaskets = [...this.baskets];
    }
    this.form.get('basket')?.setValue(this.selectedBaskets);
  }

  getItem(event: any, basket: number) {
    if (event.target.checked) {
      if (!this.selectedBaskets.includes(basket)) {
        this.selectedBaskets.push(basket);
      }
    } else {
      const index = this.selectedBaskets.indexOf(basket);
      if (index > -1) {
        this.selectedBaskets.splice(index, 1);
      }
    }
    this.form.get('basket')?.setValue(this.selectedBaskets);
  }

  ifIncluded(basket: number) {
    return this.selectedBaskets.includes(basket);
  }

  save() {
    const baskets = this.selectedBaskets;
    this.formSubmit.next({});
    this.errors.next({});
    if (!ErrorHandlerClass.AnyErrors(this.form)) {

      this.bao$.getErrors().subscribe(errors => {
        if (errors.hasOwnProperty('error'))
          this.errors.next(errors.error);
      });
      this.form.get('basket')?.setValue(baskets);
      if (this.id == 0) {
        this.bao$.addCheckoutReturn(this.form.value).subscribe(response => {
          this.message$.handle('Запрос на возврат был успешно создан', 'success');
          window.location.reload();
        });
      } else {
        this.bao$.updateCheckoutReturn(this.id, this.form.value).subscribe(response => {
          this.message$.handle('Запрос на возврат был успешно обновлен', 'success');
        });
      }
    }
  }

  approve() {
    const baskets = this.selectedBaskets;
    this.formSubmit.next({});
    this.errors.next({});
    if (!ErrorHandlerClass.AnyErrors(this.form)) {

      this.bao$.getErrors().subscribe(errors => {
        if (errors.hasOwnProperty('error'))
          this.errors.next(errors.error);
      });
      this.form.get('basket')?.setValue(baskets);
      this.bao$.updateCheckoutReturn(this.id, this.form.value).subscribe(response => {
        if (response.results) {
          this.bao$.approveCheckoutReturn(this.id).subscribe(response => {
            this.message$.handle('Заявка на возврат отправлена', 'success');
            window.location.href = '/account/return-list';
          });
        }
      });
    }
  }
}
