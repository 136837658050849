import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-bilet-menu',
  templateUrl: './bilet-menu.component.html',
  styleUrls: ['./bilet-menu.component.css']
})
export class BiletMenuComponent implements OnInit {

  isFooterActive = false;
  constructor() {
  }

  ngOnInit(): void {
  }

}
