<div class="footer__section footer__section_second section" (click)="isFooterActive = !isFooterActive" [ngClass]="{'footer__section_active': isFooterActive}">
  <div class="section__header">
    <div class="section__title title">
      <span class="title__text">О компании</span>
    </div>
    <div class="section__media media">
      <svg class="media__ico">
        <use xlink:href="/assets/img/sprite.svg#arrowBottom"></use>
      </svg>
    </div>
  </div>
  <div class="section__main">
    <nav class="footer__nav nav">
      <ul class="nav__list">
        <li class="nav__item">
          <a class="nav__link" routerLink="/about">О компании</a>
        </li>
        <li class="nav__item">
          <a class="nav__link" routerLink="/feedback">Обратная связь</a>
        </li>
        <li class="nav__item">
          <a class="nav__link" routerLink="/faq">Часто задаваемые вопросы</a>
        </li>
        <li class="nav__item">
          <a class="nav__link" routerLink="/public-offert">Публичная оферта</a>
        </li>
        <li class="nav__item">
          <a class="nav__link" routerLink="/return-bilet">Правила возврата билетов</a>
        </li>
      </ul>

    </nav>
  </div>
</div>