<footer class="layout__footer footer">
  <div class="container">
    <div class="row">
      <div class="col col_3 col_tab-12 col_mob-12">
        <!-- Логотип -->
        <app-footer-logo></app-footer-logo>
      </div>
      <div class="col col_2 col_tab-4 col_mob-12">
        <!-- Меню -->
        <app-bilet-menu></app-bilet-menu>
      </div>
      <div class="col col_4 col_tab-4 col_mob-12">
        <!-- Меню -->
        <app-about-menu></app-about-menu>
      </div>
      <div class="col col_3 col_tab-4 col_mob-12">
        <!-- Меню -->
        <app-contact-block></app-contact-block>
      </div>
    </div>
  </div><!-- //container -->
</footer><!-- //footer -->
