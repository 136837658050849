import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { FRAME_URL, VKAPP_URL } from 'src/app/exports/constans';

@Component({
  selector: '[data-app-vidget-tab]',
  templateUrl: './vidget-tab.component.html',
  styleUrls: ['./vidget-tab.component.css']
})
export class VidgetTabComponent implements OnInit {
  event: any = false;
  tab: string = 'site';
  color: string = "#F93C00";
  buttonText: string = "Купить билет";
  formSubmit: Subject<any> = new Subject();
  defaultValue = new Subject();
  errors: Subject<any> = new Subject();
  generatedText: string;
  vkurl: string;

  constructor(
    private param$: ActivatedRoute
  ) {
    this.param$.params.subscribe(params => {
      if (typeof params == "object" && params.event_id) {
        this.event = params.event_id;
        this.vkurl = VKAPP_URL + "#" + this.event;
      }
    });
  }

  ngOnInit(): void {
  }

  setTab(tab: string) {
    this.tab = tab;
    console.log(this.tab);
  }

  getColor(color: any) {
    this.color = color;
  }

  generate() {
    const buttonTextValue = this.buttonText;
    const colorValue = this.color;
    const dynamicHTML = `
      <button class="ButtonBiletTutWidget" style="color: #ffffff;
          background-color: ${colorValue};
          font-weight: 650;
          font-size: 0.7rem;
          border: none;
          padding: 0.7rem 1rem 0.7rem;
          width: fit-content;
          border-radius: 0.5rem;
          text-align: center" data-event="${this.event}">${buttonTextValue}</button>
      <script>
          var domainValue = "${FRAME_URL}";
          document.write("<script type='text/javascript' src='https://"+ "${FRAME_URL}" +"/assets/js/widget.js?" + "v=" + Date.now() +
              "'><\\/script>");
      </script>
    `;
    this.generatedText = dynamicHTML;
  }

}
