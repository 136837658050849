import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateBuyComponent } from './date-buy.component';



@NgModule({
  declarations: [DateBuyComponent],
  imports: [
    CommonModule
  ],
  exports: [DateBuyComponent]
})
export class DateBuyModule { }
