<div class="breadcrumbs">
    <div class="container">
        <div class="breadcrumbs__list">
            <div class="breadcrumbs__item" *ngFor="let item of items | keyvalue">
                <a class="breadcrumbs__link" [routerLink]="getItem(item.value, 'path')" *ngIf="getKey(item.key) != items.length">
                    <div class="breadcrumbs__title title">
                        <span class="title__text">{{ getItem(item.value, 'name') }}</span>
                    </div>
                    <div class="breadcrumbs__media media">
                        <svg class="media__ico">
                            <use xlink:href="/assets/img/sprite.svg#arrowRight"></use>
                        </svg>
                    </div>
                </a>
                <span class="breadcrumbs__link" *ngIf="getKey(item.key) == items.length">
                    <div class="breadcrumbs__title title">
                        <span class="title__text">{{ getItem(item.value, 'name') }}</span>
                    </div>
                </span>
            </div>
        </div>
    </div>
</div>
