import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrapServicePageComponent } from './wrap-service-page.component';
import { WrapServicePageItemModule } from '../wrap-service-page-item/wrap-service-page-item.module';


@NgModule({
  declarations: [
    WrapServicePageComponent,
  ],
  imports: [
    CommonModule,
    WrapServicePageItemModule,
  ],
  exports: [WrapServicePageComponent]
})
export class WrapServicePageModule { }
