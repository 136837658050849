<div class="footer__section footer__section_second section" (click)="isFooterActive = !isFooterActive" [ngClass]="{'footer__section_active': isFooterActive}">
    <div class="section__header">
      <div class="section__title title">
        <span class="title__text">Билеты</span>
      </div>
      <div class="section__media media">
        <svg class="media__ico">
          <use xlink:href="/assets/img/sprite.svg#arrowBottom"></use>
        </svg>
      </div>
    </div>
    <div class="section__main">
      <nav class="footer__nav nav">
        <ul class="nav__list">
          <li class="nav__item">
            <a class="nav__link" routerLink="/concert">Концерты</a>
          </li>
          <li class="nav__item">
            <a class="nav__link" routerLink="/perfomance">Спектакли</a>
          </li>
          <li class="nav__item">
            <a class="nav__link" routerLink="/exhibition">Выставки</a>
          </li>
          <li class="nav__item">
            <a class="nav__link" routerLink="/show">Шоу</a>
          </li>
          <li class="nav__item">
            <a class="nav__link" routerLink="/children">Детям</a>
          </li>
        </ul>

      </nav>
    </div>
</div>