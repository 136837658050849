import { Component, OnInit, Input } from '@angular/core';
import { IEventItem } from '../../../../../exports/interfaces/IEventItem';

@Component({
  selector: '[data-app-wrap-main-org-page]',
  templateUrl: './wrap-main-org-page.component.html',
  styleUrls: ['./wrap-main-org-page.component.css']
})
export class WrapMainOrgPageComponent implements OnInit {

  //@Input() items: IEventItem[] = []
  type: string = 'items';

  constructor() { }

  setType(type: string) {
    this.type = type;
  }

  ngOnInit(): void {
  }

}
