<div class="section__header">
    <div class="container">
        <div class="row">
            <div class="col col_10 col_big-desktop-8 col_tab-12">
                <div class="wysiwyg">
                    <h1>Результаты поиска</h1>
                </div>
            </div>
            <div class="col col_2 col_big-desktop-4 col_tab-12">
                <div class="form-item">
                    <div class="form-item__main">
                        <div class="form-item__field">
                            <div data-app-select [values]="[
                            {
                                name: 'По названию',
                                value: 'name'
                            },
                            {
                                name: 'По дате',
                                value: 'dates__start_date'
                            }
                            ]"
                            (data)="ordering($event)"
                            ></div>
                        </div>
                    </div>
                </div><!-- //form-item -->
            </div>
        </div>
    </div>
</div>
<div class="section__search search">
    <div class="container">
        <div class="search__formular">
            <form class="search__form" [formGroup]="form">
                <div class="row">
                    <div class="col col_4 col_big-desktop-12">
                        <div class="form-item form-item_before">
                            <div class="form-item__main">
                                <div class="form-item__field">
                                    <div data-app-input 
                                      placeholder="Поиск мероприятия..."
                                      setClass="form-item__input"
                                      formControlName="search"
                                      sugg_url="/api/event/"
                                      [get_data]="{
                                        name: 'name',
                                        value: 'name'
                                      }"
                                      [value]="getQuery('search')"
                                    ></div>
                                </div>
                                <div class="form-item__media media">
                                    <svg class="media__ico">
                                        <use xlink:href="/assets/img/sprite.svg#search"></use>
                                    </svg>
                                </div>
                            </div>
                        </div><!-- //form-item -->
                    </div>
                    <div class="col col_2 col_big-desktop-3 col_desktop-4 col_mob-12">
                        <div class="form-item  form-item_before">
                            <div class="form-item__main">
                                <div class="form-item__field">
                                    <div data-app-select 
                                      placeholder="Жанр" 
                                      [values]="genre"
                                      formControlName="genre"
                                      [value]="getQuery('genre')"
                                    ></div>
                                </div>
                                <div class="form-item__media media">
                                    <svg class="media__ico">
                                        <use xlink:href="/assets/img/sprite.svg#star"></use>
                                    </svg>
                                </div>
                            </div>
                        </div><!-- //form-item -->
                    </div>
                    <div class="col col_2 col_big-desktop-3 col_desktop-4 col_mob-12">
                        <div class="form-item  form-item_before">
                            <div class="form-item__main">
                                <div class="form-item__field">
                                    <div data-app-input-date
                                      setClass="form-item__input datepicker"
                                      placeholder="Дата"
                                      formControlName="dates__start_date"
                                      [value]="getQuery('dates__start_date')"
                                    ></div>
                                </div>
                                <div class="form-item__media media">
                                    <svg class="media__ico">
                                        <use xlink:href="/assets/img/sprite.svg#date"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col col_2 col_big-desktop-3 col_desktop-4 col_mob-12">
                        <div class="form-item  form-item_before">
                            <div class="form-item__main">
                                <div class="form-item__field">
                                    <div data-app-select 
                                      placeholder="Площадка" 
                                      [values]="area"
                                      formControlName="area"
                                      [value]="getQuery('area')"
                                    ></div>
                                </div>
                                <div class="form-item__media media">
                                    <svg class="media__ico">
                                        <use xlink:href="/assets/img/sprite.svg#location"></use>
                                    </svg>
                                </div>
                            </div>
                        </div><!-- //form-item -->
                    </div>
                    <div class="col col_2 col_big-desktop-3 col_desktop-12">
                        <div class="form-item">
                            <div class="form-item__main">
                                <div class="form-item__field">
                                    <button class="btn" type="button" (click)="searchForm()">Поиск</button>
                                </div>
                            </div>
                        </div><!-- //form-item -->
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>