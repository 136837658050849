import { Component, OnInit, Input } from '@angular/core';
import { IEventItem } from '../../../../../exports/interfaces/IEventItem';
import { ISelectValue } from 'src/app/exports/interfaces/ISelectValue';
import { STATIC_EVENTS, STATIC_EVENT_PARAMS, STATIC_EVENT_RESPONSE, TAGS_LIST } from 'src/app/exports/constans';
import { UserService } from 'src/app/services/user.service';
import { IUserItem } from 'src/app/exports/interfaces/IUserItem';

@Component({
  selector: '[data-app-wrap-filter-product]',
  templateUrl: './wrap-filter-product.component.html',
  styleUrls: ['./wrap-filter-product.component.css']
})
export class WrapFilterProductComponent implements OnInit {

  items: IEventItem[] = [];
  user: IUserItem;
  isLoading: boolean = true;
  searchQuery: string = '';

  response = STATIC_EVENT_RESPONSE;
  params = STATIC_EVENT_PARAMS;
  elements = STATIC_EVENTS;

  ordering: ISelectValue[] = [
    {
      name: "Сначала новые",
      value: false
    },
    {
      name: "Сначала старые",
      value: "-start_date"
    }
  ];

  constructor(
    private user$: UserService
  ) {

    this.user$.user.subscribe(user => {
      if (user && typeof user == "object") {
        this.user = user;
      }
    });

    STATIC_EVENTS.subscribe(items => {
      if (Array.isArray(items))
        this.items = items;
      this.isLoading = false;
    });

  }

  updateSearchParams() {
    let params = TAGS_LIST.getValue();
    const searchQueryParam = 'search';

    if (this.searchQuery.trim() !== '') {
      params = params.filter((param) => param.key !== searchQueryParam);
      params.push({
        name: 'Search Query',
        value: this.searchQuery,
        key: searchQueryParam,
      });
    } else {
      params = params.filter((param) => param.key !== searchQueryParam);
    }

    TAGS_LIST.next(params);
  }

  getOrder(value: string) {
    let params = TAGS_LIST.getValue();

    // remove any existing ordering parameter
    params = params.filter((param) => param.key !== 'ordering');

    if (value === 'newest') {
      // add ordering for newest first
      params.push({
        name: 'Сортировка по дате',
        value: '-start_date', // descending order by start_date
        key: 'ordering',
      });
    }
    TAGS_LIST.next(params);
  }

  ngOnInit(): void {

  }

}
