<div class="layout__section section">
    <div class="section__header">
        <div class="wysiwyg">
            <h1 *ngIf="id == 0">Создание рубрики</h1>
            <h1 *ngIf="id > 0">Редактирование рубрики</h1>
        </div>
    </div>
    <div class="section__main">
        <div class="account">
            <div class="account__design design">
                <div class="formular">
                    <form [formGroup]="form">
                        <fieldset>
                            <div class="row">
                                <div class="col col_10 col_tab-12">
                                    <div class="form__group group">
                                        <div class="group__header">
                                            <div class="wysiwyg">
                                                <h3>Где рекламировать мероприятие?</h3>
                                            </div>
                                        </div>
                                        <div class="group__main">
                                            <div class="row">
                                                <div class="col col_12">
                                                    <div class="form-item">
                                                        <div class="form-item__header">
                                                            <label class="form-item__title"
                                                                   for="event__name">Название
                                                                рубрики</label>
                                                        </div>
                                                        <div class="form-item__main">
                                                            <div class="form-item__field">
                                                                <div data-app-input
                                                                    [defaultValue]="defaultValue"
                                                                    [submitSubject]="formSubmit"
                                                                    setClass="form-item__input"
                                                                    [inputErrors]="errors"
                                                                    [c]="form.controls.name"
                                                                    placeholder="Введите данные"
                                                                    formControlName="name"
                                                                >
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div><!-- //form-item -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form__group group">
                                        <div class="group__header">
                                            <div class="wysiwyg">
                                                <h3>2. Позиция на главной странице</h3>
                                            </div>
                                        </div>
                                        <div class="group__main">
                                            <div class="row">
                                                <div class="col col_12">
                                                    <div class="form-item">
                                                        <div class="form-item__header">
                                                            <label class="form-item__title">Порядковый номер
                                                                блока</label>
                                                        </div>
                                                        <div class="form-item__main">
                                                            <div class="form-item__field">
                                                                <div data-app-counter
                                                                    [defaultValue]="defaultValue"
                                                                    [submitSubject]="formSubmit"
                                                                    setClass="form-item__input"
                                                                    [inputErrors]="errors"
                                                                    [c]="form.controls.position"
                                                                    placeholder="Введите данные"
                                                                    formControlName="position"
                                                                >
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div><!-- //form-item -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form__group group">
                                        <div class="group__header">
                                            <div class="wysiwyg">
                                                <h3>3. Мероприятия и жанры </h3>
                                            </div>
                                        </div>
                                        <div class="group__main">
                                            <div class="row">
                                                <div class="col col_6 col_mob-12">
                                                    <div class="form-item">
                                                        <div class="form-item__header">
                                                            <label class="form-item__title">Список
                                                                мероприятий в рубрике</label>
                                                        </div>
                                                        <div class="form-item__main">
                                                            <div class="form-item__field">
                                                                <div data-app-select
                                                                    [defaultValue]="defaultValue"
                                                                    [submitSubject]="formSubmit"
                                                                    setClass="form-item__input"
                                                                    [inputErrors]="errors"
                                                                    [c]="form.controls.events"
                                                                    sugg_url="/api/event/"
                                                                    [get_data]="{
                                                                        name: 'name',
                                                                        value: 'id'
                                                                    }"
                                                                    [multiple]="true"
                                                                    placeholder="Введите данные"
                                                                    formControlName="events"
                                                                >
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col col_6 col_mob-12">
                                                    <div class="form-item">
                                                        <div class="form-item__header">
                                                            <label class="form-item__title">Жанры для
                                                                рубрики</label>
                                                        </div>
                                                        <div class="form-item__main">
                                                            <div class="form-item__field">
                                                                <div data-app-select
                                                                    [defaultValue]="defaultValue"
                                                                    [submitSubject]="formSubmit"
                                                                    setClass="form-item__input"
                                                                    [inputErrors]="errors"
                                                                    [c]="form.controls.genres"
                                                                    sugg_url="/api/event_genre"
                                                                    [get_data]="{
                                                                        name: 'name',
                                                                        value: 'id'
                                                                    }"
                                                                    [multiple]="true"
                                                                    placeholder="Введите данные"
                                                                    formControlName="genres"
                                                                >
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col col_12">
                                                    <div class="form-item">
                                                        <div class="form-item__main">
                                                            <div class="form-item__field">
                                                                <div data-app-photo-component
                                                                    [defaultValue]="defaultValue"
                                                                    [submitSubject]="formSubmit"
                                                                    setClass="form-item__input"
                                                                    [inputErrors]="errors"
                                                                    [c]="form.controls.image"
                                                                    formControlName="image"
                                                                >
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div><!-- //form-item -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group__footer">
                                            <div class="row">
                                                <div class="col col_2 col_desktop-3 col_tab-6 col_mob-12">
                                                    <button type="button" (click)="delete()" class="btn btn_tertiary">Удалить</button>
                                                </div>
                                                <div class="col col_10 col_desktop-10 col_tab-6 col_mob-12">
                                                    <button type="button" (click)="save()" class="btn">
                                                        <span *ngIf="id == 0">Создать</span>
                                                        <span *ngIf="id > 0">Обновить</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
