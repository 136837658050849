<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">№ {{ item.event }}</span>
        </div>
    </div>
</td>
<td style="width: auto;max-width: 25rem;">
    <div class="table__group group">
        {{ item.name }}
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <button class="btn btn_secondary" (click)="exportEvent(item)">Экспорт</button>
        </div>
    </div>
</td>