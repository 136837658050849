<div class="row">
    <div class="col col_10 col_tab-12">
        <div class="switchtabs">
            <div class="switchtabs__header">
                <div class="switchtabs__list">
                    <div class="switchtabs__item" [ngClass]="{switchtabs__item_active: tab == 'site'}"
                        (click)="setTab('site')">
                        <span class="switchtabs__title">Вставка на сайт</span>
                    </div>
                    <div class="switchtabs__item" [ngClass]="{switchtabs__item_active: tab == 'vk'}"
                        (click)="setTab('vk')">
                        <span class="switchtabs__title">VK виджет</span>
                    </div>
                </div>
            </div>
            <div class="switchtabs__body">
                <div class="switchtabs__list">
                    <div class="switchtabs__item" [ngClass]="{switchtabs__item_active: tab == 'site'}">
                        <form>
                            <fieldset>
                                <div class="form__group group">
                                    <div class="row">
                                        <div class="col col_4 col_tab-12">

                                            <div class="group__header">
                                                <div class="wysiwyg">
                                                    <h3>Пример кнопки</h3>
                                                </div>
                                            </div>
                                            <div class="group__main">
                                                <div class="form-item h-auto">
                                                    <div class="form-item__main">
                                                        <button class="btn btn_custom w-auto" [ngStyle]="{
                                                            'background-color' : color }" [innerHTML]="buttonText">
                                                            Купить билет
                                                        </button>
                                                    </div>
                                                </div><!-- //form-item -->
                                            </div>

                                        </div>
                                        <div class="col col_8 col_tab-12">

                                            <div class="group__header">
                                                <div class="wysiwyg">
                                                    <h3>Настройка
                                                        кнопки</h3>
                                                </div>
                                            </div>
                                            <div class="group__main">
                                                <div class="form-item h-auto">
                                                    <div class="form-item__header">
                                                        <label class="form-item__title" for="event__btn-text">Текст
                                                            кнопки</label>
                                                    </div>
                                                    <div class="form-item__main">
                                                        <div class="form-item__field">
                                                            <input id="event__btn-text" name="event__btn-text"
                                                                class="form-item__input" type="text"
                                                                placeholder="Купить билет" required
                                                                [(ngModel)]="buttonText">
                                                        </div>
                                                    </div>
                                                </div><!-- //form-item -->

                                                <div class="form-item h-auto">
                                                    <div class="form-item__header">
                                                        <label class="form-item__title" for="event__code">Выбор
                                                            цвета</label>
                                                    </div>
                                                    <div class="form-item__main">
                                                        <div class="form-item__field">
                                                            <div data-app-color-list (data)="getColor($event)"
                                                                [color]="color"></div>
                                                        </div>
                                                    </div>
                                                </div><!-- //form-item -->

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col col_12">
                                            <div class="group__footer">
                                                <div class="row">
                                                    <div class="col col_12">
                                                        <button type="submit" class="btn" (click)="generate()">
                                                            Сгенерировать
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                        <textarea rows="20" readonly *ngIf="generatedText" class="gen__code"
                            [value]="generatedText"></textarea>
                    </div>
                    <div class="switchtabs__item" [ngClass]="{switchtabs__item_active: tab == 'vk'}">
                        <form>
                            <fieldset>
                                <div class="form__group group">
                                    <div class="group__main">
                                        <div class="form-item h-auto">
                                            <div class="form-item__header">
                                                <label class="form-item__title" for="event__vk">Ссылка для кнопки
                                                    сообщества для покупки билета</label>
                                            </div>
                                            <div class="form-item__main">
                                                <div class="form-item__field">
                                                    <input id="event__vk" class="form-item__input" type="text"
                                                        placeholder="vkcode" readonly [defaultValue]="vkurl">
                                                </div>
                                            </div>
                                        </div><!-- //form-item -->
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>