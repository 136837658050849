<td>
    <div class="table__group group">
        <div class="group__main">
            <span class="group__item no-wrap">№ {{ item.id }}</span>
        </div>
    </div>
</td>
<td style="width: auto;max-width: 25rem;">
    <div class="table__group group" *ngFor="let groupedItem of groupedItems">
        <div class="group__media media" style="margin: 0.1rem;">
            <a class="media__link" [routerLink]="['/event', groupedItem.items[0].product.id]">
                <div data-app-image class="media__img" [src]="groupedItem.items[0].product.afisha.image.small"></div>
            </a>
        </div>
        <div class="group__main">
            <a class="group__item group__item_100" [routerLink]="['/event', groupedItem.items[0].product.id]">
                {{ groupedItem.items[0].product.name }}
            </a>
            <span class="group__item group__item_300">
                {{ getTypes(groupedItem.items[0]) }}
            </span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main">
            <ng-container *ngIf="getUserId() !== null">
                <a class="group__item" [routerLink]="['/account/statistic/user', getUserId()]">
                    {{ getUserInfo() }}
                </a>
            </ng-container>
            <ng-container *ngIf="getUserId() === null">
                <span class="group__item">
                    {{ getUserInfo() }}
                </span>
            </ng-container>
        </div>
    </div>
</td>
<td>
    <div class="table__group group" *ngFor="let groupedItem of groupedItems">
        <div class="group__main">
            <span class="group__item">{{ item.created_at | date: "dd.MM.Y" }}</span>
            <span class="group__item">{{ item.created_at | date: "HH:mm" }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group" *ngFor="let groupedItem of groupedItems">
        <div class="group__main">
            <span *ngFor="let category of getCategoryQuantities(groupedItem.items)">
                <span *ngIf="category.place">{{ category.place }}</span>
                <span *ngIf="category.categoryName && !category.place">{{ category.categoryName }}</span>
                <br>
            </span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group" *ngFor="let groupedItem of groupedItems">
        <div class="group__main" *ngIf="getTotalDiscount(groupedItem.items) > 0">
            <span class="group__item discount"><strong>{{ getTotalPrice(groupedItem.items) }} р.</strong></span>
            <span class="group__item">{{ getTotalDiscount(groupedItem.items) }} р.</span>
            <span class="group__item group__item_500">{{ item.status.name }}</span>
        </div>
        <div class="group__main" *ngIf="getTotalDiscount(groupedItem.items) == 0">
            <span class="group__item">{{ getTotalPrice(groupedItem.items) }} р.</span>
            <span class="group__item" [ngClass]="{
                'item_300': item.status.id == 2,
                'item_400': item.status.id == 1,
                'item_500': item.status.id == 3
            }">{{ item.status.name }}</span>
        </div>
    </div>
</td>
<td>
    <div class="table__group group">
        <div class="group__main" *ngIf="item.status.code == 'paid'">
            <button class="btn btn_secondary" (click)="repeatBilets($event)">Отправить билет</button>
        </div>
    </div>
</td>