<div class="layout__section section">
    <div class="section__header">
        <div class="row">
            <div class="col col_9 col_big-desktop-8 col_tab-12">
                <div class="wysiwyg">
                    <h1>Интеграция мероприятий</h1>
                </div>
            </div>
            <div class="col col_3 col_big-desktop-4 col_tab-12">
                <span class="btn modal-init" (click)="openModal()">Токен</span>
            </div>
        </div>
    </div>
    <div class="main__loading" *ngIf="isLoading">
        <img src="/assets/img/content/loader/loader-spin.svg" alt="">
    </div>
    <div class="section__main">
        <div class="account">
            <div class="account__sales">
                <div class="table">
                    <table class="table table_custom table_sales" *ngIf="items.length > 0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th style="width: auto;max-width: 25rem;">Мероприятие</th>
                                <th>Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr data-app-wrap-service-page-item *ngFor="let item of items" [item]="item">

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>